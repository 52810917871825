import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { JobidCardService } from "@shared/services/jobid-card/jobid-card.service";
import { GeneralStatus, JobGateReadiness } from "@shared/models/jobid-card/jobid-card.model";
import { ProfileService } from "@shared/services";
import { AgGridAngular } from "@ag-grid-community/angular";
import {
  FilterChangedEvent,
  GridReadyEvent,
  RowClickedEvent,
  SelectionChangedEvent,
  SideBarDef,
  SortChangedEvent,
} from "@ag-grid-community/core";
import { IServerSideDatasource } from "ag-grid-enterprise";
import { filter, takeUntil } from "rxjs/operators";
import { JobCardMasterService } from "@shared/services/job-card-master/job-card-master.service";
import { environment } from "@env/environment";
import { SelectLineRendererComponent } from "@shared/components/cell-renderer/select-line-renderer.component";
import { JobidCardDetailService } from "@shared/services/jobid-card-detail/jobid-card-detail.service";
import { ApplyColumnStateParams } from "@ag-grid-community/core/dist/cjs/columns/columnApi";
import { ToastrService } from "ngx-toastr";
import { ExportExcelService } from "@shared/services/export-excel/export-excel.service";
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: "jbid-card-list",
  templateUrl: "./jobid-card-list.component.html",
  styleUrls: ["./jobid-card-list.component.scss"],
})
export class JobidCardListComponent implements OnDestroy, OnInit {
  @ViewChild("agGrid") agGrid!: AgGridAngular;
  protected readonly environment = environment;

  /** Unsubscribe from observable streams when the component is destroyed or when the infinite scroll datasource is destoyed. */
  private destroy = new Subject<void>();

  private dataSource!: IServerSideDatasource;
  userHasAffiliates = false;
  columnDefs;
  defaultColDef;
  rowModelType;
  paginationPageSize;
  cacheBlockSize;
  rowData: [] = [];
  sideBar: SideBarDef;
  selectedLines = 0;
  queryParamsFilters!: any;
  frameworkComponents = {
    selectLineRendererComponent: SelectLineRendererComponent,
  };
  _linkModeActive = false;
  private affiliate: string | undefined;
  readonly JOB_CARDS_SHEET_NAME = "Job cards";
  readonly JOB_CARDS_EXPORT_FILE_NAME = "Job cards list.xlsx";
  private totalItems = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private jobidCardService: JobidCardService,
    public jobCardMasterService: JobCardMasterService,
    private profileService: ProfileService,
    private elementRef: ElementRef,
    public jobidCardDetailService: JobidCardDetailService,
    private toastrService: ToastrService,
    public exportExcelService: ExportExcelService,
    private translate: TranslateService,
  ) {

    this.columnDefs = this.getColumnDef();
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      sortable: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    };
    this.rowModelType = "serverSide";
    this.paginationPageSize = this.jobidCardService.defaultSizePerPage;
    this.cacheBlockSize = this.jobidCardService.defaultSizePerPage;
    this.sideBar = {
      toolPanels: [
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressPivotMode: true,
            suppressValues: true,
            suppressRowGroups: true,
          },
          minWidth: 225,
          width: 225,
          maxWidth: 225,
        },
      ],
      position: "right",
    };
  }

  @HostListener("unloaded")
  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  openJobCardModal() {
    this.jobidCardService.createJobCardModal();
  }

  openLinkToJobCardMasterModal() {
    this.jobCardMasterService.createLinkJobCardToJobCardMasterModal();
  }

  public isRowSelectable = (rowNode: any) => {
    return !!rowNode.data && rowNode.data.jobCardMasterId == null;
  };
  getRowNodeId(node: any) {
    return node.id;
  }

  ngOnInit() {
    this.profileService.noAffiliates$.pipe(takeUntil(this.destroy)).subscribe((noAffiliates) => {
      this.userHasAffiliates = !noAffiliates;
    });
    this.translate.onLangChange.pipe(takeUntil(this.destroy)).subscribe((event: LangChangeEvent) => {
      this.columnDefs = this.getColumnDef();
    });

    this.profileService.affiliate$.pipe(filter(Boolean), takeUntil(this.destroy)).subscribe((affiliate) => {
      this.affiliate = affiliate.value;
      this.dataSource = this.jobidCardService.getDatasource();
      if (this.agGrid) {
        this.agGrid.api.setServerSideDatasource(this.dataSource);
      }
    });
    this.jobCardMasterService.linkModeActive$.pipe(takeUntil(this.destroy)).subscribe((active) => {
      this._linkModeActive = active;
      this.updateColunDefs();
      if (this.agGrid?.api) {
        this.agGrid.api.deselectAll();
        this.agGrid.api.refreshCells();
      }
    });
    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe((params) => {
      this.queryParamsFilters = params;
    });
    this.refreshDataAfterMassiveCreation();
  }

  private refreshDataAfterMassiveCreation() {
    let previousLoading = false;
    this.jobidCardDetailService.loadingMassiveCreation$.subscribe((loading) => {
      if (loading) {
        previousLoading = true;
      } else {
        if (previousLoading) {
          const x: ApplyColumnStateParams = {
            state: [
              {
                colId: "creationDate",
                sort: "desc",
              },
            ],
          };
          this.agGrid.columnApi.applyColumnState(x);
        }
        previousLoading = loading;
      }
    });
  }

  private updateColunDefs() {
    this.columnDefs = this.getColumnDef();
    if (this.agGrid?.api) {
      this.agGrid.api.setColumnDefs(this.columnDefs);
    }
  }

  onGridReady(params: GridReadyEvent) {
    params.api.setServerSideDatasource(this.dataSource);

    params.columnApi.applyColumnState({ state: this.jobidCardService.columnState });
    params.columnApi.autoSizeAllColumns();

    let filterModel = this.jobidCardService.filterDataModel ?? {};
    if (this.queryParamsFilters) {
      Object.keys(this.queryParamsFilters)
        .filter((column) => this.getColumnDef().find((c) => c.field === column))
        .forEach((key) => {
          filterModel = {
            ...filterModel,
            [key]: { filterType: "text", type: "equals", filter: this.queryParamsFilters[key] },
          };
        });
      params.api.setFilterModel(filterModel);
    }
  }

  onModelUpdated() {
    if (this.agGrid.api && this.agGrid.api.getDisplayedRowCount() <= 0) {
      this.agGrid.api.showNoRowsOverlay();
    } else {
      this.agGrid.api.hideOverlay();
    }
  }

  onRowClicked($event: RowClickedEvent) {
    if (!this._linkModeActive) {
      const rowData: any = $event.node.data;
      this.router.navigate([`../detail/${this.affiliate}/${rowData.id}/general`], {
        relativeTo: this.route,
      });
      this.elementRef.nativeElement.remove();
    }
  }

  getColumnDef() {
    return [
      {
        headerName: this.translate.instant('jobCardList.id'),
        field: "id_sel",
        filter: false,
        sortable: false,
        hide: !this._linkModeActive,
        cellRenderer: "selectLineRendererComponent",
        minWidth: 100,
      },
      {
        headerName: this.translate.instant('jobCardList.id'),
        field: "id",
        filter: "agNumberColumnFilter",
        sortable: true,
        hide: this._linkModeActive,
        minWidth: 100,
      },
      {
        headerName: this.translate.instant('jobCardList.jobTitle'),
        field: "jobTitle",
        filter: "agTextColumnFilter",
        minWidth: 300,
      },
      {
        headerName: this.translate.instant('jobCardList.jobCardMasterTitle'),
        field: "jobCardMasterTitle",
        filter: "agTextColumnFilter",
        minWidth: 300,
      },
      {
        headerName: this.translate.instant('jobCardList.dependency'),
        field: "isLeader",
        filter: "agTextColumnFilter",
        valueGetter: (params: { data: { isLeader: boolean, parentId: boolean } }) => {
          return this.dependency(params.data.isLeader, params.data.parentId);
        },
      },
      {
        headerName: this.translate.instant('jobCardList.workOrderNumber'),
        field: "workOrderNumber",
        filter: "agTextColumnFilter",
        valueGetter: (params: { data: { fromSap: boolean; workOrderNumber: any; generalStatus: string } }) => {
          if (params.data?.fromSap && params.data?.generalStatus !== GeneralStatus.CANCELLED) {
            return params.data?.workOrderNumber;
          }
          return "";
        },
      },
      {
        headerName: this.translate.instant('jobCardList.priority'),
        field: "priority",
        filter: "agTextColumnFilter",
      },
      {
        headerName: this.translate.instant('jobCardList.sitePlatform'),
        field: "sitePlatform",
        filter: "agTextColumnFilter",
        minWidth: 200,
      },
      {
        headerName: this.translate.instant('jobCardList.generalStatus'),
        field: "generalStatus",
        filter: "agSetColumnFilter",
        filterParams: {
          values: Object.values(GeneralStatus),
        },
      },
      {
        headerName: this.translate.instant('jobCardList.generalStatus'),
        field: "actualStatus",
        filter: "agSetColumnFilter",
        filterParams: {
          values: Object.values(JobGateReadiness),
        },
      },
      {
        headerName: this.translate.instant('jobCardList.potentialReadiness'),
        field: "potentialReadiness",
        filter: "agSetColumnFilter",
        minWidth: 250,
        filterParams: {
          values: Object.values(JobGateReadiness),
        },
      },
      {
        headerName: this.translate.instant('jobCardList.jobLeader'),
        field: "jobLeader",
        filter: "agTextColumnFilter",
        minWidth: 200,
      },
      {
        headerName: this.translate.instant('jobCardList.maximumPob'),
        field: "maximumPob",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: this.translate.instant('jobCardList.startDate'),
        field: "startDate",
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          filterOptions: ["inRange"],
        },
      },
      {
        headerName: this.translate.instant('jobCardList.endDate'),
        field: "endDate",
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          filterOptions: ["inRange"],
        },
      },
      {
        headerName: this.translate.instant('jobCardList.owner') ,
        field: "owner",
        filter: "agTextColumnFilter",
      },
      {
        headerName: this.translate.instant('jobCardList.discipline'),
        field: "discipline",
        filter: "agTextColumnFilter",
      },
      {
        field: "revisionCode",
        filter: "agTextColumnFilter",
        headerName: this.translate.instant('jobCardList.revisionCode'),
        minWidth: 200,
      },
      {
        field: "masterNotification",
        filter: "agTextColumnFilter",
        headerName: this.translate.instant('jobCardList.masterNotification'),
        minWidth: 200,
      },
      {
        headerName: this.translate.instant('jobCardList.workOrderSystemStatus'),
        field: "workOrderSystemStatus",
        filter: "agTextColumnFilter",
        minWidth: 210,
      },
      {
        headerName: this.translate.instant('jobCardList.workOrderUserStatus'),
        field: "workOrderUserStatus",
        filter: "agTextColumnFilter",
        minWidth: 250,
      },
      {
        field: "functionalLocation",
        filter: "agTextColumnFilter",
        headerName: this.translate.instant('jobCardList.functionalLocation'),
      },
      {
        field: "attributes",
        filter: "agTextColumnFilter",
        headerName: this.translate.instant('jobCardList.attributes'),
        minWidth: 250,
      },
      {
        field: "campaignOption",
        filter: "agTextColumnFilter",
        headerName: this.translate.instant('jobCardList.campaignOption'),
      },
      {
        field: "workOrderType",
        filter: "agTextColumnFilter",
        headerName: this.translate.instant('jobCardList.workOrderType'),
        minWidth: 200,
      },
      {
        headerName: this.translate.instant('jobCardList.creationDate'),
        field: "creationDate",
        filter: "agDateColumnFilter",
        minWidth: 200,
        filterParams: {
          inRangeInclusive: true,
          filterOptions: ["inRange"],
        },
      },
    ];
  }

  onTabChange(tabName: string) {
    if (tabName === "Job-Card-Master") {
      this.jobCardMasterService.activeLinkMode(false);
      this.router.navigate([`../../job-card-master`], {
        relativeTo: this.route,
      });
    }
  }

  onSelectionChanged($event: SelectionChangedEvent) {
    this.jobCardMasterService.updateJobCardListToLink($event.api.getSelectedRows().map((row) => row.id));
    this.selectedLines = $event.api.getSelectedRows().length;
  }

  onFilterChanged($event: FilterChangedEvent) {
    this.jobidCardService.filterDataModel = $event.api.getFilterModel();
  }

  onSortChanged($event: SortChangedEvent) {
    this.jobidCardService.columnState = $event.columnApi.getColumnState();
  }

  exportToExcel() {
    // Get total rows count
    const totalRows = this.jobidCardService.totalItems;
    this.jobidCardService.getDatasource(totalRows).getRows({
      request: {
        startRow: 0,
        endRow: totalRows,
        filterModel: {},
        sortModel: [],
      },
      success: ({ rowData }: { rowData: any }) => {
        const formattedDate = this.prepareData(rowData);
        this.exportExcelService.exportToExcel(
          formattedDate,
          this.JOB_CARDS_SHEET_NAME,
          this.JOB_CARDS_EXPORT_FILE_NAME,
          "Job cards exported successfully",
        );
      },
    });
  }

  resetFilter() {
    this.agGrid.api.setFilterModel(null);
    this.agGrid.columnApi.applyColumnState({
      state: this.agGrid.columnApi.getColumnState().map((c) => ({ ...c, sort: null })),
    });
  }

  private dependency(isLeader: boolean, parentId: boolean) {
    if (isLeader) {
      return this.translate.instant('jobCardList.leader');
    } else if (parentId !== null && parentId !== undefined) {
      return this.translate.instant('jobCardList.dependent');
    } else {
      return this.translate.instant('jobCardList.none');
    }
  }

  prepareData(rowDataList: any[]) {
    return rowDataList.map((rowData: any) => {
      return {
        id: rowData.id,
        jobTitle: rowData.jobTitle,
        jobCardMasterTitle: rowData.jobCardMasterTitle,
        dependency: this.dependency(rowData.isLeader, rowData.parentId),
        jobCardMasterId: rowData.jobCardMasterId,
        masterClassification: rowData.masterClassification,
        workOrderNumber: rowData.workOrderNumber,
        priority: rowData.priority,
        sitePlatform: rowData.sitePlatform,
        generalStatus: rowData.generalStatus,
        actualStatus: rowData.actualStatus,
        potentialReadiness: rowData.potentialReadiness,
        startDate: rowData.startDate,
        endDate: rowData.endDate,
        owner: rowData.owner,
        discipline: rowData.discipline,
        revisionCode: rowData.revisionCode,
        masterNotification: rowData.masterNotification,
        workOrderUserStatus: rowData.workOrderUserStatus,
        workOrderSystemStatus: rowData.workOrderSystemStatus,
        functionalLocation: rowData.functionalLocation,
        attributes: rowData.attributes,
        dgsValue: rowData.dgsValue,
        lotiValue: rowData.lotiValue,
        campaignOption: rowData.campaignOption,
        workOrderType: rowData.workOrderType,
        maximumPob: rowData.maximumPob,
        fromSap: rowData.fromSap,
        jobLeader: rowData.jobLeader,
        creationDate: rowData.creationDate,
      };
    });
  }
}

import { Injectable } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { JobScope, JobScopeInput } from "@shared/models/prerequisites/job-scope.model";
import { DetailFormService } from "../detail-form.service";
import { DocumentDTO } from "@shared/models/prerequisites/document-dto.model";
import filter from "lodash-es/filter";


@Injectable({
  providedIn: "root",
})
export class JobScopeFormService extends DetailFormService {

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  getFormData(jobScope: JobScope | undefined): JobScopeInput | undefined {
    if (!jobScope && !this.form) {
      return undefined;
    }

    if (!this.form)
      return jobScope
        ? this.copy(jobScope)
        : undefined;

    const formRawValue = this.form?.getRawValue();
    return this.copy(formRawValue);
  }

  createForm(
    jobScope?: JobScope,
    methodStatementDocumentLink?: DocumentDTO[],
    aroRloDocumentLink?: DocumentDTO[],
    wellInterventionDocumentLink?: DocumentDTO[],
    isModeReadOnly?: boolean
  ) {

    if(this.form != undefined && !isModeReadOnly){
      return this.form;
    }

    if(isModeReadOnly || this.form == undefined){
      this.data = jobScope;
      this.form = this.fb.group({
        methodStatement: [{value: jobScope?.methodStatement, disabled: true}, []],
        methodStatementStatus: [jobScope?.methodStatementStatus, jobScope?.methodStatementStatus ? [Validators.required] : []],
        methodStatementDocumentLink: [methodStatementDocumentLink, []],

        // Cost/Budget
        costBudget: [{ value: jobScope?.costBudget, disabled: this.isModeReadOnly }, []],
        yearlyBudgetRequired: [jobScope?.yearlyBudgetRequired , []],
        describeActions: [jobScope?.describeActions || "", []],

        komJra: [{value: jobScope?.komJra, disabled: true}, []],
        aroRloRequired: [jobScope?.aroRloRequired, jobScope?.komJra ? [Validators.required] : []],
        aroRloDocumentLink: [aroRloDocumentLink, []],

        rfmNumber: [{value: jobScope?.rfmNumber, disabled: true}, []],
        rfmNumberValue: [jobScope?.rfmNumberValue, []],
        rfmTitle: [jobScope?.rfmTitle, []],
        rfmClass: [jobScope?.rfmClass, []],
        rfmEngineeringStatus: [jobScope?.rfmEngineeringStatus, []],

        wellIntervention: [{value: jobScope?.wellIntervention, disabled: true}, []],
        wellInterventionStatus: [jobScope?.wellInterventionStatus, []],
        wellInterventionDocumentLink: [wellInterventionDocumentLink, []],
        id: [jobScope?.id],
      });

      this.setUpOnChanges();
    }
    return this.form;
  }

  private setUpOnChanges() {
    this.getControl("komJra")?.valueChanges.subscribe((value) => {
      if (value) {
        this.getControl("aroRloRequired")?.setValidators([Validators.required]);
      } else {
        this.getControl("aroRloRequired")?.setValidators(null);
        this.getControl("aroRloRequired")?.setValue(null);
        this.getControl("aroRloDocumentLink")?.setValue([]);
      }
    });

    this.getControl("rfmNumber")?.valueChanges.subscribe((value) => {
      if (value) {
        this.getControl("rfmEngineeringStatus")?.setValidators([Validators.required]);
        this.getControl("rfmNumberClass")?.setValidators([Validators.required]);
        this.getControl("rfmNumberValue")?.setValidators([Validators.required]);
      } else {
        this.getControl("rfmEngineeringStatus")?.setValidators(null);
        this.getControl("rfmEngineeringStatus")?.setValue(null);
        this.getControl("rfmNumberClass")?.setValidators(null);
        this.getControl("rfmNumberClass")?.setValue(null);
        this.getControl("rfmNumberValue")?.setValidators(null);
        this.getControl("rfmNumberValue")?.setValue(null);
      }
    });

    this.getControl("costBudget")?.valueChanges.subscribe((value) => {
      if (!value) {
        this.getControl("yearlyBudgetRequired")?.setValidators(null);
        this.getControl("yearlyBudgetRequired")?.patchValue(true);
        this.getControl("describeActions")?.patchValue(null);
      }
    });
    
    this.getControl("wellIntervention")?.valueChanges.subscribe((value) => {
      if (value) {
        this.getControl("wellInterventionStatus")?.setValidators([Validators.required]);
      } else {
        this.getControl("wellInterventionStatus")?.setValidators(null);
        this.getControl("wellInterventionStatus")?.setValue(null);
        this.getControl("wellInterventionDocumentLink")?.setValue([]);
      }
    });
  }

  getFormDocuments(documentDTOList: DocumentDTO[]) {
    if (!documentDTOList && !this.form) {
      return [];
    }

    if(!this.form){
      const filteredDocs :DocumentDTO[] = filter(documentDTOList, {'section': 'SCOPE'});
      return filteredDocs.map(doc => this.copyDocument(doc));
    }

    const methodStatement: DocumentDTO[] = this.form?.controls["methodStatementDocumentLink"]?.value ?? [];
    const aroRlo: DocumentDTO[] = this.form?.controls["aroRloDocumentLink"]?.value ?? [];
    const wellIntervention: DocumentDTO[] = this.form?.controls["wellInterventionDocumentLink"]?.value ?? [];

    return [
      methodStatement.map((doc) => this.copyDocument(doc)),
      aroRlo.map((doc) => this.copyDocument(doc)),
      wellIntervention.map((doc) => this.copyDocument(doc)),
    ].reduce((acc, val) => acc.concat(val), []);
  }

  private copy(jobScope: JobScope): JobScopeInput {
    return {
      id: jobScope.id,
      methodStatement: jobScope.methodStatement,
      methodStatementStatus: jobScope.methodStatementStatus,
      komJra: jobScope.komJra,
      aroRloRequired: jobScope.aroRloRequired,
      rfmNumber: jobScope.rfmNumber,
      rfmClass: jobScope.rfmClass,
      rfmNumberValue: jobScope.rfmNumberValue,
      rfmTitle: jobScope.rfmTitle,
      rfmEngineeringStatus: jobScope.rfmEngineeringStatus,
      wellIntervention: jobScope.wellIntervention,
      wellInterventionStatus: jobScope.wellInterventionStatus,
      costBudget: jobScope.costBudget,
      yearlyBudgetRequired: jobScope.yearlyBudgetRequired,
      describeActions: jobScope.describeActions,
    };
  }
}

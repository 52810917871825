export const environment = {
  production: true,
  apiBaseUrl: "https://aggregator-dev.jbid.iasp.tgscloud.net/aggregator",
  quantumBaseUrl: "https://navigator.phusionim.com/",
  instrumentationKey: "dfa56c50-04ac-4c5d-badd-241422ad9aa4",
  clientId: "16c5867a-d92b-46a9-a66c-a513f598d3a5",
  tenantId: "329e91b0-e21f-48fb-a071-456717ecc28e",
  aadBackendScope: "api://0e1b55b3-90d9-412f-bfa0-d45f730a8f35/aggregator_scope",
  redirectUrl: "/jobid-card/list",
  angolaUsers: "Tepa-Users",
  denmarkUsers: "Tepdk-Users",
  nigeriaDWUsers: "Tepng-dw-Users",
  nigeriaJVUsers: "Tepng-jv-Users",
  hotjarEnvironmentId: "",
  linkJobcardToJcmActive: true,
};

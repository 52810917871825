import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ProfileService } from "../profile/profile.service";
import { Apollo } from "apollo-angular";
import { GET_METIER_BY_AFFILIATE_QUERY } from "@graphql/jobid-card/discipline/queries/metier.gql";
import { Metier } from "@shared/models/siteplatform/site-platform.model";

@Injectable({
  providedIn: "root",
})
export class MetiersService {

  private _metiers$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  metiers$: Observable<string[]> = this._metiers$.asObservable();

  constructor(private profileService: ProfileService, private apollo: Apollo) {

    this.profileService.affiliate$.subscribe({ next: affiliate => {
      this.apollo
      .query<any>({
        query: GET_METIER_BY_AFFILIATE_QUERY,
        variables: {
          affiliate: affiliate.value,
        },
      })
      .subscribe(({ data }) => {
        const metiers = data.getMetiersByAffiliate as Metier[];
        this._metiers$.next(metiers.map((metier) => metier.name));
      });
    } });
  }

}

import {Component, Input} from '@angular/core';
import {Classification} from "@shared/services/job-card-master/job-card-master.service";

@Component({
  selector: 'jbid-classification-card',
  templateUrl: './classification-card.component.html',
  styleUrls: ['./classification-card.component.css']
})
export class ClassificationCardComponent {
  @Input() classification: Classification | undefined;
}

import gql from "graphql-tag";

const GET_JOB_CARD_MASTER_LIST_QUERY = gql`
  query ($affiliate: String, $page: Int, $size: Int, $sortBy: String!, $direction: String!, $filter: [Filter]) {
    getJobCardsMasterPage(
      affiliate: $affiliate
      page: $page
      size: $size
      sortBy: $sortBy
      direction: $direction
      filter: $filter
    ) {
      jobCardMastersList {
        id
        title
        generalStatus
        actualStatus
        potentialReadiness
        affiliate
        mainSite
        startDate
        endDate
        owner
        creationDate
        lastModified
        isCampaign
        nbrOfJobCards
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export { GET_JOB_CARD_MASTER_LIST_QUERY };

const GET_ALL_JOB_CARD_MASTER_LIST_QUERY = gql`
  query ($affiliate: String) {
    getJobCardMasterLinkedAllowed(affiliate: $affiliate) {
      id
      title
      owner
      isScopeFrozen
    }
  }
`;

export { GET_ALL_JOB_CARD_MASTER_LIST_QUERY };

<mat-card *ngIf="classification">
  <h6 class="card-title">{{classification.label}}</h6>
  <div class="horizontal-bar mb-3"></div>
  <div class="d-flex justify-content-center align-middle classification">
    <span class="classification-title">{{classification.count}}</span>
  </div>
  <div class="d-flex" style="margin-top: 10px; width: 100%">
    <div [class]="'progress-ready'" [style.width.%]="classification.countReady*100"></div>
    <div [class]="'progress-awaiting-preparation'" [style.width.%]="classification.countAwaitingPreparation*100"></div>
    <div [class]="'progress-delayed'" [style.width.%]="classification.countDelayed*100"></div>
    <div [class]="'progress-ready-to-activate'" [style.width.%]="classification.countReadyToActivate*100"></div>
    <div [class]="'progress-draft'" [style.width.%]="classification.countDraft*100"></div>
  </div>
</mat-card>

import gql from "graphql-tag";

const GET_WORK_ORDER_DETAILS_QUERY = gql`
  query ($id: Float, $affiliate: String) {
    getWorkOrderDetailsAggregate(id: $id, affiliate: $affiliate) {
      id
      description
      priority
      startDate
      endDate
      metier
      disciplineCategory
      discipline
      masterNotification
      functionalLocation
      functionalLocationDescription
      activityType
      jobEstimatedCost
      fromSap
      sitePlatform
      wbsElement
      wbsDescription
      startDate
      endDate
      revisionCode
      materials {
        id
        description
        unloadingPoint
        quantityRequested
        quantityStock
        quantitySite
        sapOrigin
        materialNumber
        workOrderNumber
        procurementStatus
        eta
        qaQcCertificate
        affiliate
        itemNumber
      }
    }
  }
`;

export { GET_WORK_ORDER_DETAILS_QUERY };

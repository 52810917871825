import {Injectable} from "@angular/core";

import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ProfileService} from "@shared/services";
import {Apollo} from "apollo-angular";
import {IServerSideGetRowsParams} from "ag-grid-enterprise";
import {constructFilter} from "@shared/table-utils";
import {GET_JOB_CARD_LIST_QUERY} from "@graphql/jobid-card/queries/jobid-card-list.gql";
import {ColumnState} from "@ag-grid-community/core/dist/cjs/columns/columnModel";

@Injectable({
  providedIn: "root",
})
export class JobidCardService {
  defaultSizePerPage = 30;
  totalItems = 0;
  filterDataModel!: { [p: string]: any };
  columnState!: ColumnState[];
  constructor(private apollo: Apollo, private profileService: ProfileService, private modalService: NgbModal) {}

  getDatasource(totalItems?: number) {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const pageToGet = params.request.startRow / this.defaultSizePerPage;
        const filterToSend = totalItems ? [] : constructFilter(params.request.filterModel);
        const affiliate = this.profileService.getAffiliate().value;
        if (affiliate) {
          this.apollo
            .query<any>({
              query: GET_JOB_CARD_LIST_QUERY,
              variables: {
                affiliate: this.profileService.getAffiliate().value,
                page: pageToGet,
                size: totalItems || this.defaultSizePerPage,
                sortBy: params.request.sortModel.length == 0 ? "" : params.request.sortModel[0]?.colId,
                direction: params.request.sortModel.length == 0 ? "" : params.request.sortModel[0]?.sort,
                filter: filterToSend,
              },
            })
            .subscribe((result) => {
              params.success({
                rowData: result.data.jobCardAggregated.jobCardsAggregate,
                rowCount: result.data.jobCardAggregated.totalItems,
              });
              if (Object.keys(params.request.filterModel).length == 0) {
                this.totalItems = result.data.jobCardAggregated.totalItems;
              }
            });
        } else {
          params.success({
            rowData: [],
            rowCount: 0,
          });
        }
      },
    };
  }
}

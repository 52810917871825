import {Injectable} from "@angular/core";
import {AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {DetailFormService} from "@routes/jobid-card/detail/prerequisites/detail-form.service";
import {CustomValidators} from "@shared/components/forms/custom.validators";
import {aActivityType, JobCardGeneralDetailsAggregate} from "@shared/models/jobid-card/GeneralDetailsAggregate.model";
import {DatePipe} from "@angular/common";
import {GeneralStatus, JobidCardShiftType, ProjectWBSDiscipline} from "@shared/models/jobid-card/jobid-card.model";
import {getDateFromString} from "@shared/utils";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class GeneralDetailsFormService extends DetailFormService {
  jobAttributesConfig: any = {
    siteVisitOnly: { name: this.translate.instant('generalDetails.siteVisitOnly'),  value: false },
    sece: { name: this.translate.instant('generalDetails.sece'), value: false },
    dgs: { name: this.translate.instant('generalDetails.dgs'), value: false },
    loti: { name: this.translate.instant('generalDetails.loti/LTI'), value: false },
    campaign: { name: this.translate.instant('generalDetails.campaign'), value: false },
  };
  private disciplines: string[] = [];
  private revisionCodes?: string[];
  private offshoreFocalPoints?: string[];
  projectWbsDisciplinesAndSubGroups: ProjectWBSDiscipline[] = [];

  public setDisciplines(disciplines: string[]) {
    this.disciplines = disciplines;
    const control = this.form?.get("discipline");
    control?.markAsDirty();
    control?.updateValueAndValidity();
  }

  public setRevisionCodes(revisionCodes: string[]) {
    this.revisionCodes = revisionCodes;
    const control = this.form?.get("revisionCode");
    control?.markAsDirty();
    control?.updateValueAndValidity();
  }

  public setOffshoreFocalPoint(affiliateUsersName: string[]) {
    this.offshoreFocalPoints = affiliateUsersName;
    const control = this.form?.get("offshoreFocalPoint");
    control?.markAsDirty();
    control?.updateValueAndValidity();
  }

  public setProjectWbsDisciplinesAndSubGroups(projectWbsDisciplinesAndSubGroups: ProjectWBSDiscipline[]) {
    this.projectWbsDisciplinesAndSubGroups = projectWbsDisciplinesAndSubGroups;
  }

  constructor(private fb: UntypedFormBuilder, private datePipe: DatePipe, private translate: TranslateService,) {
    super();
  }

  private jobAttributesToForm(generalDetails?: JobCardGeneralDetailsAggregate) {
    const jobAttributesData = generalDetails?.attributes?.split("/") || [];

    const jobAttributes: any = {
      siteVisitOnly: { name: "Site visit only", value: false },
      sece: { name: "SECE", value: false },
      dgs: { name: "DGS", value: false },
      loti: { name: "LOTI/LTI", value: false },
      campaign: { name: "Campaign", value: false },
    };

    for (const element of jobAttributesData) {
      if (generalDetails?.siteVisitOnly) {
        jobAttributes["siteVisitOnly"].value = true;
      }

      if (element.toLowerCase().includes("sece")) {
        jobAttributes["sece"].value = true;
      }

      if (element.toLowerCase().includes("dgs")) {
        jobAttributes["dgs"].value = true;
      }

      if (element.toLowerCase().includes("loti")) {
        jobAttributes["loti"].value = true;
      }

      if (element.toLowerCase().includes("campaign")) {
        jobAttributes["campaign"].value = true;
      }
    }

    return jobAttributes;
  }

  private disiplineValidatorFunction: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return this.disciplines?.includes(control?.value) ? null : { validDiscipline: false };
  };

  private validateRevisionCode = (control: AbstractControl): ValidationErrors | null => {
    const revisionCode: string = control?.value;
    return !revisionCode || this.revisionCodes?.includes(revisionCode)
      ? null
      : {
          invalidRevisionCode: true,
        };
  };

  private offshoreFocalPointValidatorFunction: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return this.offshoreFocalPoints?.includes(control?.value) ? null : {validOffshoreFocalPoint: false};
  };

  validateDGS = () => {
    const dgs = this.form?.get("dgs")?.value;
    const dgsValue = this.form?.get("dgsValue")?.value;
    return !dgs || (dgs && dgsValue != null && dgsValue != "" && dgsValue != undefined) ? null : { validateDGS: false };
  };
  validateLOTI = () => {
    const loti = this.form?.get("loti")?.value;
    const lotiValue = this.form?.get("lotiValue")?.value;
    return !loti || (loti && lotiValue != null && lotiValue != "" && lotiValue != undefined)
      ? null
      : { validateLOTI: false };
  };
  validateWbs = () => {
    const wbsElement = this.form?.get("wbsElement")?.value;
    const wbsDescription = this.form?.get("wbsDescription")?.value;
    if ((wbsElement || "").trim().length > 0 && (wbsDescription || "").trim().length <= 0) {
      return { wbsElementNotFound: true };
    }

    return null;
  };

  createForm(readonly: boolean, generalDetails?: JobCardGeneralDetailsAggregate) {
    if (this.form != undefined && !readonly){
      return this.form;
    }

    this.data = generalDetails;
    const jobAttributes: any = this.jobAttributesToForm(generalDetails);
    const numberOfNight = this.calculateNights(generalDetails);
    const disciplinePrimavera:ProjectWBSDiscipline | undefined = this.getDisciplineLabelFromSubGroupCode(generalDetails?.projectWbsGroupCode);
    const subGroupePrimavera:{code:string, label:string} | undefined = this.getSubGroupPrimaveraLabelFromDiscipline(disciplinePrimavera, generalDetails?.projectWbsGroupCode);

    this.form = this.fb.group(
      {
        // Job identification
        description: [
          {
            value: generalDetails?.description,
            disabled: generalDetails?.fromSap,
          },
          [Validators.required],
        ],
        functionalLocation: [
          {
            value: generalDetails?.functionalLocation,
            disabled: generalDetails?.fromSap,
          },
          [Validators.required],
        ],
        functionalLocationDescription: [
          {
            value: generalDetails?.functionalLocationDescription,
            disabled: generalDetails?.fromSap,
          },
          [Validators.required],
        ],
        revisionCode: [generalDetails?.revisionCode, [this.validateRevisionCode]],

        discipline: [
          {
            value: generalDetails?.discipline,
            disabled: generalDetails?.fromSap,
          },
          [Validators.required, this.disiplineValidatorFunction],
        ],
        activityType: [
          {
            value: generalDetails?.activityType
              ? aActivityType.find((element) => element.value.startsWith(generalDetails?.activityType))?.value || null
              : null,
            disabled: generalDetails?.fromSap || generalDetails?.siteVisitOnly,
          },
          [Validators.required],
        ],
        jobLeader: [generalDetails?.jobLeader, [Validators.required]],
        notificationNumber: [
          {
            value: generalDetails?.masterNotification,
            disabled: generalDetails?.fromSap,
          },
          [],
        ],
        workOrder: [generalDetails?.fromSap ? generalDetails?.workOrderNumber : "", []],
        jobEstimatedCost: [generalDetails?.jobEstimatedCost, [Validators.min(0)]],

        // Job attributes
        siteVisitOnly: [
          {
            value: generalDetails?.siteVisitOnly,
            disabled: true,
          },
          [],
        ],
        sece: [{ value: jobAttributes["sece"].value, disabled: true }, []],
        seceFromSap: generalDetails?.seceFromSap,
        dgs: [{ value: jobAttributes["dgs"].value, disabled: true }],
        loti: [{ value: jobAttributes["loti"].value, disabled: true }, []],
        campaign: [{ value: jobAttributes["campaign"].value, disabled: true }, []],
        lotiValue: generalDetails?.lotiValue,
        dgsValue: [generalDetails?.dgsValue, []],
        campaignOption: generalDetails?.campaignOption,

        // Job planning
        startDate: [
          {
            value: generalDetails?.startDate ? getDateFromString(generalDetails?.startDate?.toString()) : null,
            disabled: generalDetails?.fromSap,
          },
          [Validators.required],
        ],
        endDate: [
          {
            value: generalDetails?.endDate ? getDateFromString(generalDetails?.endDate?.toString()) : null,
            disabled: generalDetails?.fromSap,
          },
          [Validators.required],
        ],
        priority: [generalDetails?.priority, [Validators.required]],
        shiftType: [
          generalDetails?.shiftType ? generalDetails?.shiftType : JobidCardShiftType.DAY_SHIFT,
          [Validators.required],
        ],
        maximumPob: [generalDetails?.maximumPob, [Validators.required]],
        offshoreFocalPoint: [generalDetails?.offshoreFocalPoint, [Validators.required, this.offshoreFocalPointValidatorFunction]],
        numberOfNights: [numberOfNight, []],
        activityId: generalDetails?.activityId,
        projectId: generalDetails?.projectId,
        projectWbsGroupFullCode: generalDetails?.projectWbsGroupCode,
        projectWbsDisciplineLabel: disciplinePrimavera?.projectWbsDiscipline.label === "" || disciplinePrimavera?.projectWbsDiscipline.label === undefined ?
            this.extractDisciplineCodeFromGroupCode(generalDetails?.projectWbsGroupCode) : disciplinePrimavera?.projectWbsDiscipline.label,
        projectWbsGroupLabel: subGroupePrimavera?.label === "" || subGroupePrimavera?.label === undefined ?
            this.extractSubGroupCodeFromGroupCode(generalDetails?.projectWbsGroupCode) : subGroupePrimavera?.label,
        wbsElement: generalDetails?.wbsElement,
        wbsDescription: generalDetails?.wbsDescription,
        flocPlatform:generalDetails?.flocPlatform,
      },
      {
        validators: [
          CustomValidators.startDateAfterEndDate("startDate", "endDate"),
          this.validateDGS,
          this.validateLOTI,
          this.validateWbs,
        ],
      },
    );
    return this.form;
  }

  public calculateNights(generalDetails: JobCardGeneralDetailsAggregate | undefined) {
    let numberOfNight;
    if (generalDetails?.startDate && generalDetails?.endDate) {
      const endDate = getDateFromString(generalDetails?.endDate?.toString());
      const startDate = getDateFromString(generalDetails?.startDate?.toString());
      if (endDate !== null && startDate !== null) {
        numberOfNight = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
      }
    }
    return numberOfNight;
  }

  getFormData(
    generalDetails: JobCardGeneralDetailsAggregate,
    generalStatus: GeneralStatus,
    twoWeeksFrozenValidated: boolean,
  ): Partial<JobCardGeneralDetailsAggregate> {
    if (!this.form) return this.copy(generalDetails);
    const formRawValue = this.form?.getRawValue();
    const data: Partial<JobCardGeneralDetailsAggregate> = {
      id: generalDetails.id,
      jobTitle: generalDetails?.jobTitle,
      priority: formRawValue?.priority,
      workOrderNumber:
        formRawValue?.workOrder === "" ? generalDetails.workOrderNumber : Number(formRawValue?.workOrder),
      description: formRawValue?.description,
      generalStatus: generalStatus,
      startDate: this.datePipe.transform(formRawValue?.startDate, "dd/MM/yyyy") ?? formRawValue?.startDate,
      endDate: this.datePipe.transform(formRawValue?.endDate, "dd/MM/yyyy") ?? formRawValue?.endDate,
      discipline: formRawValue?.discipline,
      revisionCode: formRawValue?.revisionCode,
      masterNotification: String(formRawValue?.notificationNumber),
      functionalLocation: formRawValue?.functionalLocation,
      functionalLocationDescription: formRawValue?.functionalLocationDescription,
      activityType: formRawValue?.activityType || "",
      shiftType: formRawValue?.shiftType,
      sitePlatform: generalDetails.sitePlatform,
      jobLeader: formRawValue?.jobLeader,
      jobEstimatedCost: formRawValue?.jobEstimatedCost,
      maximumPob: Number(formRawValue?.maximumPob),
      fromSap: formRawValue?.workOrder !== "",
      siteVisitOnly: generalDetails?.siteVisitOnly,
      activityId: formRawValue?.activityId,
      projectId: formRawValue?.projectId,
      dgsValue: formRawValue?.dgsValue,
      lotiValue: formRawValue?.lotiValue,
      campaignOption: formRawValue?.campaignOption,
      wbsElement: formRawValue?.wbsElement,
      wbsDescription: formRawValue?.wbsDescription,
      flocPlatform:generalDetails?.flocPlatform,
      offshoreFocalPoint: formRawValue?.offshoreFocalPoint,
      twoWeeksFrozenValidated: twoWeeksFrozenValidated,
    };

    const jobAttributes: string[] = [];
    const jobAttributesConfigKeys = Object.keys(this.jobAttributesConfig);
    for (const element of jobAttributesConfigKeys) {
      const jobAttributeKey = element;
      const value = formRawValue?.[jobAttributeKey];
      if (value) {
        jobAttributes.push(jobAttributeKey);
      }
    }
    data.attributes = jobAttributes.join("/");
    return data;
  }

  copy(generalDetails: JobCardGeneralDetailsAggregate) {
    return {
      id: generalDetails.id,
      jobTitle: generalDetails?.jobTitle,
      priority: generalDetails?.priority,
      workOrderNumber: generalDetails.workOrderNumber,
      description: generalDetails?.description,
      generalStatus: generalDetails.generalStatus,
      startDate: generalDetails.startDate,
      endDate: generalDetails.endDate,
      discipline: generalDetails.discipline,
      revisionCode: generalDetails.revisionCode,
      masterNotification: generalDetails.masterNotification,
      functionalLocation: generalDetails.functionalLocation,
      functionalLocationDescription: generalDetails.functionalLocationDescription,
      activityType: generalDetails.activityType,
      shiftType: generalDetails.shiftType,
      sitePlatform: generalDetails.sitePlatform,
      jobLeader: generalDetails.jobLeader,
      jobEstimatedCost: generalDetails.jobEstimatedCost,
      maximumPob: generalDetails.maximumPob,
      fromSap: generalDetails.fromSap,
      siteVisitOnly: generalDetails?.siteVisitOnly,
      activityId: generalDetails.activityId,
      projectId: generalDetails.projectId,
      attributes: generalDetails.attributes,
      dgsValue: generalDetails.dgsValue,
      lotiValue: generalDetails.lotiValue,
      campaignOption: generalDetails.campaignOption,
      wbsElement: generalDetails.wbsElement,
      wbsDescription: generalDetails.wbsDescription,
      flocPlatform:generalDetails.flocPlatform,
      offshoreFocalPoint: generalDetails.offshoreFocalPoint,
      twoWeeksFrozenValidated: generalDetails.twoWeeksFrozenValidated,
    };
  }

  getDisciplineLabelFromSubGroupCode(subGroupCode: string | undefined): ProjectWBSDiscipline | undefined {
    if(!subGroupCode) return undefined;
    const disciplineCode = this.extractDisciplineCodeFromGroupCode(subGroupCode);
    return this.projectWbsDisciplinesAndSubGroups.find((discipline) => discipline.projectWbsDiscipline.code === disciplineCode);
  }

  extractDisciplineCodeFromGroupCode(subGroupCode: string | undefined): string  {
    return subGroupCode ? subGroupCode.includes(".") ? subGroupCode.split(".")[0] : subGroupCode : "";
  }

  extractSubGroupCodeFromGroupCode(subGroupCode: string | undefined): string  {
    return subGroupCode ? subGroupCode.includes(".") ? subGroupCode.split(".")[1] : "" : "";
  }

  getSubGroupPrimaveraLabelFromDiscipline(discipline: ProjectWBSDiscipline | undefined, subGroupCode: string | undefined): { code: string; label: string } | undefined {
    if (!discipline || !subGroupCode) return { code: "", label: "" };
    return discipline.projectWbsSubGroups.find((subGroup) => subGroup.code === subGroupCode);
  }

}

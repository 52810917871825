import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "@env/environment";
import { LicenseManager } from "@ag-grid-enterprise/core";

const licenseKey =
  "CompanyName=SHI International SAS_on_behalf_of_Total Digital Factory,LicensedGroup=TotalDigitalFactory,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-014329,ExpiryDate=19_March_2022_[v2]_MTY0NzY0ODAwMDAwMA==8edbc90ed351125e6a41f76c9a453597";
LicenseManager.setLicenseKey(licenseKey);

/**
 * Enables Hotjar, using a given hotjar site id.
 *
 * @param hjid hotjar id.
 *
 * The SHA256 to be used for let CSP's script-src-elem policy allow Hotjar usage can be computed in a bash shell:
 *
 * ```
 * echo -n "$v" |  openssl sha256 -binary | openssl base64
 * ```
 * where v has the value of hjScriptEl.innerHTML.
 * This value depends on `hjid`.
 *
 */
const enableHotjar = (hjid: string): void => {
  const hjScriptEl = document.createElement('script');
  hjScriptEl.innerHTML = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
   h._hjSettings={hjid:'${hjid}',hjsv:6}; a=o.getElementsByTagName('head')[0];
   r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })
   (window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  document.head.appendChild(hjScriptEl);
};

if (environment.production) {
  enableProdMode();
}

if (environment.hotjarEnvironmentId && environment.hotjarEnvironmentId !== '') {
  enableHotjar(environment.hotjarEnvironmentId);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

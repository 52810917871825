import { Injectable } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Impact, ImpactInput} from "@shared/models/prerequisites/impact.model";
import { DetailFormService } from "../detail-form.service";
import { DocumentDTO } from "@shared/models/prerequisites/document-dto.model";
import filter from "lodash-es/filter";

@Injectable({
  providedIn: "root",
})
export class ImpactFormService extends DetailFormService {
  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  getFormData(impact: Impact | undefined): ImpactInput | undefined {
    if (!impact && !this.form) {
      return undefined;
    }
    if (!this.form) {
      return this.copy(impact);
    }
    const formRawValue = this.form?.getRawValue();
    return this.copy(formRawValue);
  }

  createForm(
    isModeReadOnly: boolean,
    impact: Impact | undefined,
    simopsDocumentLink: DocumentDTO[],
    confinedSpaceProtocolStatusDocumentLink: DocumentDTO[],
    radiographyStatusDocumentLink: DocumentDTO[],
    divingRovDocumentLink: DocumentDTO[],
    hydroPressureDocumentLink: DocumentDTO[],
    hazardousDocumentLink: DocumentDTO[],
    regulatorApprovalDocumentLink: DocumentDTO[],
  ) {
    this.isModeReadOnly = isModeReadOnly;

    if(this.form != undefined && !isModeReadOnly){
      return this.form;
    }

    if(isModeReadOnly || this.form == undefined){

      this.data = impact;
      const setRequiredValidator = (isRequired?: boolean) => (isRequired ? [Validators.required] : []);
      this.form = this.fb.group({
        impactSimops: [{ value: impact?.impactSimops, disabled: this.isModeReadOnly }, []],
        impactSimopsStatus: [impact?.impactSimopsStatus, setRequiredValidator(impact?.impactSimops)],
        impactSimopsDocumentLink: [simopsDocumentLink, []],

        // Greenhouse Gas
        greenHouseGas: [{ value: impact?.greenHouseGas, disabled: this.isModeReadOnly }, []],
        ghgYearlyBudgetRequired: [impact?.ghgYearlyBudgetRequired , []],
        describeActions: [impact?.describeActions || "", []],

        impactShortfall: [{ value: impact?.impactShortfall, disabled: this.isModeReadOnly }, []],
        impactShortfallProduction: [impact?.impactShortfallProduction, []],
        impactShortfallInjection: [impact?.impactShortfallInjection, []],
        impactShortfallFlaring: [impact?.impactShortfallFlaring, []],

        //Permit to Work
        impactPermitToWork: [{ value: impact?.impactPermitToWork, disabled: this.isModeReadOnly }, []],
        impactPermitsStatus: [impact?.impactPermitsStatus, setRequiredValidator(impact?.impactPermitToWork)],

        impactConfinedSpace: [{ value: impact?.impactConfinedSpace, disabled: this.isModeReadOnly }, []],
        impactConfinedSpaceProtocolStatus: [
          impact?.impactConfinedSpaceProtocolStatus,
          setRequiredValidator(impact?.impactConfinedSpace),
        ],
        impactConfinedSpaceProtocolStatusDocumentLink: [confinedSpaceProtocolStatusDocumentLink, []],

        impactRadiography: [{ value: impact?.impactRadiography, disabled: this.isModeReadOnly }, []],
        impactRadiographyStatus: [impact?.impactRadiographyStatus, setRequiredValidator(impact?.impactRadiography)],
        impactRadiographyStatusDocumentLink: [radiographyStatusDocumentLink, []],

        impactDivingRov: [{ value: impact?.impactDivingRov, disabled: this.isModeReadOnly }, []],
        impactDivingRovStatus: [impact?.impactDivingRovStatus, setRequiredValidator(impact?.impactDivingRov)],
        impactDivingRovDocumentLink: [divingRovDocumentLink, []],

        impactHydroPressure: [{ value: impact?.impactHydroPressure, disabled: this.isModeReadOnly }, []],
        impactHydroPressureStatus: [impact?.impactHydroPressureStatus, setRequiredValidator(impact?.impactHydroPressure)],
        impactHydroPressureDocumentLink: [hydroPressureDocumentLink, []],

        impactHazardous: [{ value: impact?.impactHazardous, disabled: this.isModeReadOnly }, []],
        impactHazardousClassifiedArea: [
          impact?.impactHazardousClassifiedArea,
          setRequiredValidator(impact?.impactHazardous),
        ],
        impactHazardousProcedureStatus: [
          impact?.impactHazardousProcedureStatus,
          setRequiredValidator(impact?.impactHazardous),
        ],
        impactHazardousDocumentLink: [hazardousDocumentLink, []],

        regulatorApproval: [{ value: impact?.regulatorApproval, disabled: this.isModeReadOnly }, []],
        regulatorApprovalDesc: [
          impact?.regulatorApprovalDesc,
          setRequiredValidator(impact?.regulatorApproval),
        ],
        regulatorApprovalStatus: [
          impact?.regulatorApprovalStatus,
          setRequiredValidator(impact?.regulatorApproval),
        ],
        regulatorApprovalDocumentLink: [regulatorApprovalDocumentLink, []],

        id: [impact?.id],
      }, {validator: this.shortFallValidator});

      this.setUpOnChanges();
    }
    return this.form;
  }

  private setUpOnChanges() {
    this.getControl("impactSimops")?.valueChanges.subscribe((value) => {
      if (value) {
        this.getControl("impactSimopsStatus")?.setValidators([Validators.required]);
      } else {
        this.getControl("impactSimopsStatus")?.setValidators(null);
        this.getControl("impactSimopsStatus")?.patchValue(null);
      }
    });
     
    this.getControl("greenHouseGas")?.valueChanges.subscribe((value) => {
      if (!value) {
        this.getControl("ghgYearlyBudgetRequired")?.setValidators(null);
        this.getControl("ghgYearlyBudgetRequired")?.patchValue(null);
        this.getControl("describeActions")?.patchValue(null);
      }
    });

    this.getControl("impactShortfall")?.valueChanges.subscribe((value) => {
      if (!value) {
        this.getControl("impactShortfallProduction")?.setValidators(null);
        this.getControl("impactShortfallInjection")?.setValidators(null);
        this.getControl("impactShortfallFlaring")?.setValidators(null);
        this.getControl("impactShortfallProduction")?.patchValue(null);
        this.getControl("impactShortfallInjection")?.patchValue(null);
        this.getControl("impactShortfallFlaring")?.patchValue(null);
      }
    });

    ['impactShortfallProduction', 'impactShortfallInjection', 'impactShortfallFlaring'].forEach((shortFall:string) =>
      this.form.get(shortFall)?.valueChanges.subscribe((value) => {
        if(value){
          this.form.removeValidators(this.shortFallValidator);
          this.updateShortFallValidity();
        } else {
          const shortFallProduction = this.getControl("impactShortfallProduction")?.value;
          const shortFallInjection = this.getControl("impactShortfallInjection")?.value;
          const shortFallFlaring = this.getControl("impactShortfallFlaring")?.value;
          if(!shortFallProduction && !shortFallInjection && !shortFallFlaring)
          this.form.setValidators(this.shortFallValidator);
          this.updateShortFallValidity();
        }
      }));

    this.getControl("impactConfinedSpace")?.valueChanges.subscribe((value) => {
      if (value) {
        this.getControl("impactConfinedSpaceProtocolStatus")?.setValidators([Validators.required]);
      } else {
        this.getControl("impactConfinedSpaceProtocolStatus")?.setValidators(null);
        this.getControl("impactConfinedSpaceProtocolStatus")?.patchValue(null);
      }
    });

    this.getControl("impactRadiography")?.valueChanges.subscribe((value) => {
      if (value) {
        this.getControl("impactRadiographyStatus")?.setValidators([Validators.required]);
      } else {
        this.getControl("impactRadiographyStatus")?.setValidators(null);
        this.getControl("impactRadiographyStatus")?.patchValue(null);
      }
    });

    this.getControl("impactDivingRov")?.valueChanges.subscribe((value) => {
      if (value) {
        this.getControl("impactDivingRovStatus")?.setValidators([Validators.required]);
      } else {
        this.getControl("impactDivingRovStatus")?.setValidators(null);
        this.getControl("impactDivingRovStatus")?.patchValue(null);
      }
    });

    this.getControl("impactHydroPressure")?.valueChanges.subscribe((value) => {
      if (value) {
        this.getControl("impactHydroPressureStatus")?.setValidators([Validators.required]);
      } else {
        this.getControl("impactHydroPressureStatus")?.setValidators(null);
        this.getControl("impactHydroPressureStatus")?.patchValue(null);
      }
    });

    this.getControl("impactHazardous")?.valueChanges.subscribe((value) => {
      if (value) {
        this.getControl("impactHazardousClassifiedArea")?.setValidators([Validators.required]);
        this.getControl("impactHazardousProcedureStatus")?.setValidators([Validators.required]);
      } else {
        this.getControl("impactHazardousClassifiedArea")?.setValidators(null);
        this.getControl("impactHazardousClassifiedArea")?.patchValue(null);
        this.getControl("impactHazardousProcedureStatus")?.setValidators(null);
        this.getControl("impactHazardousProcedureStatus")?.patchValue(null);
      }
    });
  }

  private updateShortFallValidity() {
    this.getControl("impactShortfallProduction")?.updateValueAndValidity({ onlySelf: true, emitEvent: false});
    this.getControl("impactShortfallInjection")?.updateValueAndValidity({onlySelf: true, emitEvent: false});
    this.getControl("impactShortfallFlaring")?.updateValueAndValidity({ onlySelf: true, emitEvent: false});
  }

  private copy(impact: any): ImpactInput {
    return {
      id: impact.id,
      impactConfinedSpace: impact.impactConfinedSpace,
      impactConfinedSpaceProtocolStatus: impact.impactConfinedSpaceProtocolStatus,
      impactDivingRov: impact.impactDivingRov,
      impactDivingRovStatus: impact.impactDivingRovStatus,
      impactFlaring: impact.impactFlaring,
      impactHazardous: impact.impactHazardous,
      impactHazardousClassifiedArea: impact.impactHazardousClassifiedArea,
      impactHazardousProcedureStatus: impact.impactHazardousProcedureStatus,
      impactHotWork: impact.impactHotWork,
      impactHydroPressure: impact.impactHydroPressure,
      impactHydroPressureStatus: impact.impactHydroPressureStatus,
      impactPermitToWork: impact.impactPermitToWork,
      impactPermitsStatus: impact.impactPermitsStatus,
      impactRadiography: impact.impactRadiography,
      impactRadiographyStatus: impact.impactRadiographyStatus,
      impactShortfall: impact.impactShortfall,
      impactShortfallFlaring: impact.impactShortfallFlaring,
      impactShortfallInjection: impact.impactShortfallInjection,
      impactShortfallProduction: impact.impactShortfallProduction,
      impactShutdown: impact.impactShutdown,
      impactSimops: impact.impactSimops,
      impactSimopsStatus: impact.impactSimopsStatus,
      regulatorApproval: impact.regulatorApproval,
      regulatorApprovalDesc: impact.regulatorApprovalDesc,
      regulatorApprovalStatus: impact.regulatorApprovalStatus,
      greenHouseGas: impact.greenHouseGas,
      ghgYearlyBudgetRequired: impact.ghgYearlyBudgetRequired,
      describeActions: impact.describeActions,
    };
  }

  getFormDocuments(documentDTOList: DocumentDTO[]) {
    if (!documentDTOList && !this.form) {
      return [];
    }

    if(!this.form){
      const filteredDocs :DocumentDTO[] = filter(documentDTOList, {'section': 'IMPACT'});
      return filteredDocs.map(doc => this.copyDocument(doc));
    }

    const simops: DocumentDTO[] = this.form?.controls["impactSimopsDocumentLink"]?.value ?? [];
    const confinedSpaceProtocolStatus: DocumentDTO[] =
      this.form?.controls["impactConfinedSpaceProtocolStatusDocumentLink"]?.value ?? [];
    const confinedSpaceProcedureStatus: DocumentDTO[] =
      this.form?.controls["impactConfinedSpaceProcedureStatusDocumentLink"]?.value ?? [];
    const radiographyStatus: DocumentDTO[] = this.form?.controls["impactRadiographyStatusDocumentLink"]?.value ?? [];
    const divingRov: DocumentDTO[] = this.form?.controls["impactDivingRovDocumentLink"]?.value ?? [];
    const hydroPressure: DocumentDTO[] = this.form?.controls["impactHydroPressureDocumentLink"]?.value ?? [];
    const hazardous: DocumentDTO[] = this.form?.controls["impactHazardousDocumentLink"]?.value ?? [];
    const regulatorApproval: DocumentDTO[] = this.form?.controls["regulatorApprovalDocumentLink"]?.value ?? [];

    return [
      simops.map((doc) => this.copyDocument(doc)),
      confinedSpaceProtocolStatus.map((doc) => this.copyDocument(doc)),
      confinedSpaceProcedureStatus.map((doc) => this.copyDocument(doc)),
      radiographyStatus.map((doc) => this.copyDocument(doc)),
      divingRov.map((doc) => this.copyDocument(doc)),
      hydroPressure.map((doc) => this.copyDocument(doc)),
      hazardous.map((doc) => this.copyDocument(doc)),
      regulatorApproval.map((doc) => this.copyDocument(doc)),
    ].reduce((acc, val) => acc.concat(val), []);
  }

   shortFallValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const impactShortfall = control.get('impactShortfall')?.value;
    const impactShortfallProduction = control.get('impactShortfallProduction')?.value;
    const impactShortfallFlaring = control.get('impactShortfallFlaring')?.value;
    const impactShortfallInjection = control.get('impactShortfallInjection')?.value;
    if (impactShortfall  && !impactShortfallProduction && !impactShortfallInjection && !impactShortfallFlaring)
    {
      control.get('impactShortfallProduction')?.setErrors({oneShortFallFieldIsRequired: true})
      control.get('impactShortfallFlaring')?.setErrors({oneShortFallFieldIsRequired: true})
      control.get('impactShortfallInjection')?.setErrors({oneShortFallFieldIsRequired: true})
      return {oneShortFallFieldIsRequired: true};
    }
    return null;
  };
}

<div class="card me-2" *ngIf="generalDetails">
  <div class="card-body">
    <div class="container-fluid d-flex align-items-center">
        <div>
          <button id="returnButton" class="btn btn-link" (click)="returnToJobCardList()">
                  <span class="material-icons" aria-hidden="true">navigate_before</span>
            <span class="text-muted ms-1"> {{'generalDetails.jobCardList' | translate}} </span>
          </button>
        </div>
        <div class="vr mx-4"></div>
        <div class="w-100">
          <div class="d-flex align-items-center">
            <h5 class="mt-2" id="jobCardTitle">ID - {{generalDetails.id}} {{ generalDetails.jobTitle | uppercase }}</h5>
            <span class="badge ms-2 me-2 text-light" id="actualStatusBadge"
                  [ngClass]="actualStatusColor">{{ generalDetails.actualStatus }}</span>
            <div class="d-flex ms-auto">
              <ng-container *ngIf="!isDiscussionSection && (isModeReadOnly$ | async) === true">
                <div ngbDropdown class="dropdown px-3 text-center">
                  <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-expanded="false" id="actionsButton">{{'dropdownLabels.actions' | translate}} </button>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="actionsButton">
                    <button ngbDropdownItem class="dropdown-item" id="editButton" (click)="actionHandler('edit')" [disabled]="editionDisabled || isHistoryTab">
                      <div class="d-flex align-items-center">
                        <span class="material-icons-outlined md-24" aria-hidden="true">edit</span>
                        <span class="ps-1">{{'generalDetails.edit' | translate}}</span>
                      </div>
                    </button>
                    <button ngbDropdownItem class="dropdown-item" id="duplicateButton" (click)="actionHandler('duplicate')">
                      <div class="d-flex align-items-center">
                        <span class="material-icons-outlined md-24" aria-hidden="true">copy_all</span>
                        <span class="ps-1">{{'dropdownLabels.duplicate' | translate}}</span>
                      </div>
                    </button>
                    <button ngbDropdownItem class="dropdown-item" id="deleteButton" (click)="actionHandler('delete')" [disabled]="!isDraftOrReadyToActivate || !isAdmin" >
                      <div class="d-flex align-items-center">
                        <span class="material-icons-outlined md-24" aria-hidden="true">delete</span>
                        <span class="ps-1">{{'dropdownLabels.delete' | translate}}</span>
                      </div>
                    </button>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!isDiscussionSection && (isModeReadOnly$ | async) === false">
                <button type="button" class="btn btn-primary mx-2"
                        (click)="actionHandler('validate')">
                  <span class="material-icons" aria-hidden="true">done</span>
                  {{'createPopup.validate' | translate}}
                </button>
                <button type="button" class="btn btn-secondary mx-2"
                        (click)="actionHandler('cancel')">
                  <span class="material-icons" aria-hidden="true">close</span>
                  {{'createPopup.cancel' | translate}}
                </button>
              </ng-container>
            </div>
          </div>
          <div class="d-flex mt-3">
            <div *ngIf="generalDetails.jobCardMasterId">
              <span>Job Card Master : </span>
              <span class="header-value" id="masterHeader">
                <a href="{{ jcmLink }}" target="_blank" rel="noopener">{{ generalDetails.jobCardMasterTitle }}</a>
              </span>
            </div>
            <div *ngIf="generalDetails.jobCardMasterId" class="vr mx-2"></div>
            <div *ngIf="isLeader">
              <span>Dependency : </span>
              <span class="header-value">Leader</span>
            </div>
            <div *ngIf="isIndependent">
              <span>Dependency : </span>
              <span class="header-value">None</span>
            </div>
            <div *ngIf="isDependentOnParent">
              <span>Depends On : </span>
              <span class="header-value">
                <a href="{{ parentLink }}" target="_blank" rel="noopener">{{ truncate(generalDetails.parentTitle) }}</a>
              </span>
            </div>
            <div class="vr mx-2"></div>
            <div>
              <span>{{'generalDetails.jobCardstatus' | translate}} : </span>
              <span class="header-value" id="statusHeader">{{ generalDetails.actualStatus }}</span>
            </div>
            <div class="vr mx-2"></div>
            <div>
              <span>{{'generalDetails.lastStatusUpdate' | translate}} : </span>
              <span class="header-value"
                    id="readinessUpdateHeader">{{ lastReadinessUpdate ? (lastReadinessUpdate | date: 'dd/MM/yyyy \'at\' H:mm') : 'N/A' }}</span>
            </div>
            <div class="vr mx-2"></div>
            <div>
              <span>{{'generalDetails.site' | translate}} : </span>
              <span class="header-value" id="siteHeader">{{ generalDetails.sitePlatform }}</span>
            </div>
            <div class="vr mx-2"></div>
            <div>
              <span> {{'generalDetails.platform' | translate}} : </span>
              <span class="header-value" id="platformHeader">{{ generalDetails.flocPlatform ? generalDetails.flocPlatform : 'NA' }}</span>
            </div>
            <div class="vr mx-2"></div>
            <div>
              <span>{{'generalDetails.executionDates' | translate}} : </span>
              <span class="header-value"
                    id="execDateHeader">{{ startDate && endDate ? (startDate | date: 'dd/MM/yyyy') + ' to ' + (endDate | date: 'dd/MM/yyyy') : 'N/A' }}</span>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-3" *ngIf="generalDetails">
  <div class="row d-flex">
    <div class="col-2 px-0 scroll-general-details">
      <div class="card">
        <div class="card-body">
          <ul ngbNav class="side-nav ps-0 mb-0 bg-white">
            <li *ngFor="let tab of tabs" [ngbNavItem]="tab.route">
              <a *ngIf="!tab?.children" [routerLink]="tab.route"
                 [class.active]="selectedTab === tab.route"
                 class="nav-link d-inline-flex justify-content-between justify-items-center align-items-center text-uppercase">
                                        <span class="material-icons-outlined md-6 ps-2" aria-hidden="true"
                                              *ngIf="isPageInvalid(tab.route)">info</span>
                {{ tab.title | translate }}
              </a>

              <ng-container *ngIf="tab?.children">
                <button class="btn btn-toggle d-inline-flex justify-content-between text-uppercase"
                        (click)="collapse.toggle()" [ngClass]="{ collapsed: !collapse.collapsed }"
                        [attr.aria-expanded]="!collapse.collapsed">

                  {{ tab.title | translate}}
                  <span class="material-icons-outlined md-6 ps-2" aria-hidden="true"
                        *ngIf="isPageInvalid(tab.route)">info</span>
                </button>
                <div #collapse="ngbCollapse" [ngbCollapse]="!isPrerequisitesSection">
                  <ul class="btn-toggle-nav pb-1 text-capitalize">
                    <li *ngFor="let child of tab.children"
                        [ngbNavItem]="tab.route + '/' + child.route">
                      <a [routerLink]="tab.route + '/' + child.route" id="{{child.title}}"
                         [class.active]="selectedTab === child.route"
                         class="nav-link d-inline-flex justify-content-between align-items-center">
                                                   <span class="material-icons-outlined md-6 ps-2" aria-hidden="true"
                                                         *ngIf="isPageInvalid(child.route)">info</span>
                        {{ child.title | translate}}
                        <jbid-readiness *ngIf="prerequisites && !isDraftOrReadyToActivate"
                                        id="readiness{{ child.route }}"
                                        [readinessText]="getPrerequisitesGlobalReadiness(child.route)"
                                        [displayReadinessText]="false"></jbid-readiness>
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-container>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-10 pe-0 scroll-general-details">
      <router-outlet *ngIf="generalDetails"></router-outlet>
    </div>
  </div>
</div>


<div class="container-fluid mt-3" *ngIf="!generalDetails || generalDetails === undefined">
  <div class="card-body bg-white">
    <p>{{'generalDetails.errorJobCard' | translate}}</p>
  </div>
</div>

export class ReadinessModel {
  static readonly READY = new ReadinessModel("green", "thumb_up", "Ready");
  static readonly AWAITING_PREPARATION = new ReadinessModel("orange", "timer", "Awaiting Preparation");
  static readonly DELAYED = new ReadinessModel("red", "warning", "Delayed");
  static readonly notRequired = "Not Required";
  color: string;
  icon: string;
  text: string;
  constructor(color: string, icon: string, text: string) {
    this.color = color;
    this.icon = icon;
    this.text = text;
  }
  static globalReadinessAndCheckDisplayed(
    dtoList: { readiness: string | undefined; displayed?: boolean }[],
  ): string | undefined {
    if (!dtoList || dtoList.length === 0 || !dtoList.every((item) => item.displayed)) {
      return undefined;
    }
    return this.globalReadiness(dtoList);
  }
  static globalReadiness(dtoList: { readiness: string | undefined }[]): string | undefined {
    if (dtoList.every((dto) => !dto.readiness)) {
      return undefined;
    }
    if (!dtoList || dtoList.every((dto) => !dto.readiness) || dtoList.every((dto) => dto.readiness == this.notRequired || dto.readiness == undefined)) {
      return undefined;
    }
    if (dtoList.find((dto) => dto.readiness == "Delayed")) {
      return "Delayed";
    }
    if (dtoList.find((dto) => dto.readiness == "Awaiting Preparation")) {
      return "Awaiting Preparation";
    }
    return "Ready";
  }

  static getGlobalReadiness(readinessList: (string | undefined)[]) {
    if (!readinessList || readinessList.every((readiness) => !readiness) || readinessList.every((readiness) => readiness == this.notRequired || readiness == undefined)) {
      return undefined;
    }
    if (readinessList.find((readiness) => readiness == "Delayed")) {
      return "Delayed";
    }
    if (readinessList.find((readiness) => readiness == "Awaiting Preparation")) {
      return "Awaiting Preparation";
    }
    return "Ready";
  }
}

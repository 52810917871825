<div class="modal-header h2">Job card duplication</div>
<div class="modal-body">
  <p>You are about to duplicate this job card. It will save preparation performed in the prerequisites section. The general details page of the duplicated job card might need to be reviewed.</p>
  <p>For SAP job cards, informing the new work order number will overwrite general detail information</p>
  <form [formGroup]="duplicateFormGroup">
    <div class="form-group py-2">
      <label for="sapWorkOrder" class="form-label">SAP Work Order <span class="text-muted">(Inform the work order of this job card)</span></label>
      <input type="text" class="form-control" id="sapWorkOrder" formControlName="sapWorkOrder" [ngClass]="{ 'is-invalid': duplicateFormGroup.get('sapWorkOrder')?.invalid && duplicateFormGroup.get('sapWorkOrder')?.dirty }">
      <div class="text-danger pt-1" *ngIf="invalidWorkOrder">{{INVALID_WORK_ORDER}}</div>
      <div class="text-danger pt-1" *ngIf="workOrderAlreadyUsed">{{WORK_ORDER_ALREADY_USED}}</div>
    </div>
    <div class="form-group py-2">
      <label for="jobCardName" class="form-label">Job card name <span class="form-mandatory pe-1">*</span> <span class="text-muted">(Fill in the title of the job card)</span></label>
      <input type="text" class="form-control" id="jobCardName" formControlName="jobCardName" required>
    </div>
    <div class="form-group py-2">
      <label for="site" class="form-label">Site <span class="form-mandatory pe-1">*</span> <span class="text-muted">(Inform the site of the duplicated job card)</span></label>
      <input id="typeaheadformat" type="text" id="site" class="form-control" title="site" formControlName="site"
                           [ngClass]="{ 'is-invalid': duplicateFormGroup.get('site')?.invalid && duplicateFormGroup.get('site')?.dirty }"
                           [ngbTypeahead]="search" [resultFormatter]="siteFormatter"/>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" id="cancelButton" (click)="onCancel()">Cancel</button>
  <button type="button" class="btn btn-primary" id="confirmButton" [disabled]="duplicateFormGroup.invalid" (click)="onDuplicate()">Duplicate</button>
</div>

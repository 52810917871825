<form class="row d-flex" [formGroup]="documentLinkModalFormService.documentForm">
  <div class="modal-header">
    <div class="d-flex flex-row modal-title">
      <span class="material-icons-outlined text-primary" aria-hidden="true">link</span>
      <h5 class="modal-title mx-2">Document Link</h5>
    </div>
    <button type="button" class="btn btn-close m-0" aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"></button>
  </div>

  <div class="modal-body">
    <div class="container">
      <div class="row d-flex py-2 m-lg-1" *ngIf="!readonly">
        <div class="col-3">
          <div class="form-group">
            <label class="form-label">
              <span class="form-mandatory">*</span>
              Name
            </label>
            <input type="text" class="form-control" formControlName="name" [ngClass]="{
                'is-invalid': false
              }" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="form-label">
              <span class="form-mandatory">*</span>
              Document Link
            </label>
            <input type="text" class="form-control" formControlName="documentLink" [ngClass]="{
                'is-invalid': false
              }" />
          </div>
        </div>
        <div class="col-2 py-1 m-lg-3">
          <button type="button" [disabled]="!documentLinkModalFormService.isDocumentValid()" (click)="addNewDocumentLink()"
            class="btn btn-primary">
            Add Link
          </button>
        </div>
      </div>
      <div class="col-3 m-3">
        <p style="font-weight: bold; font-size: 110%">&nbsp;Link ({{ getDocumentLinkNumber(documents) }})</p>

        <div #documents *ngFor="let document of documents; let i = index">
          <div class="document-link" *ngIf="document.name !== '' ">
            <button class="btn btn-sm btn-tertiary" *ngIf="!readonly" (click)="removeLink(i)"
              type="button">
              <span class="material-icons">remove_circle</span>
            </button>

            <a href="{{ document.link }}" target="_blank" rel="noopener"> {{ document.name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal-footer" style="gap: 0">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Close</button>
  </div>
</form>

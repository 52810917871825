<div *ngIf="userHasAffiliates;else noAffiliateMessage" class="card wrapper">
  <div class="overview-container">
    <h4>{{'jobCardList.overview' | translate }}</h4>
  </div>

  <div class="card-body">

    <div class="d-flex mb-3">
      <div class="me-auto p-2">
        <div class="input-group ms-4 mb-2 left">
          <div class="input-group-prepend">
            <button (click)="onTabChange('Job-Card-list')" [ngClass]="{
                      'btn-primary': true
                    }" class="btn" id="Job-Card-list" type="button">{{'jobCardList.jobCardList' | translate }}
            </button>
            <button (click)="onTabChange('Job-Card-Master')" [ngClass]="{
                      'btn-primary': false
                    }" class="btn" id="Job-Card-Master" type="button">{{'jobCardList.jobCardMaster' | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="p-2 px-0 me-1">
        <button type="button" class="btn btn-sm btn-tertiary justify-content-center align-items-center"
                (click)="exportToExcel()">
          <span class="material-icons">download</span>{{'jobCardList.exportCsv' | translate}}
        </button>
      </div>
      <div class="p-2 px-0">
        <div class="" *ngIf="(jobCardMasterService.linkModeActive$| async ) === false">
          <div *ngIf="environment.linkJobcardToJcmActive">
            <button id="active-link-jc2jcm-mode" type="button"
                    class="btn btn-sm btn-secondary  d-flex flex-row justify-content-center align-items-center"
                    (click)="jobCardMasterService.activeLinkMode(true)">
              <span class="material-icons" aria-hidden="true">add_link</span>
              {{'jobCardList.linkJobCards' | translate }}
            </button>
          </div>
        </div>

        <div class="" *ngIf="jobCardMasterService.linkModeActive$ | async ">
          <div >
            <button id="link-jc2jcm-cancel" type="button" class="btn btn-secondary  mx-2 d-flex flex-row justify-content-center align-items-center" (click)="jobCardMasterService.activeLinkMode(false)">
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div class="p-2 px-0">
        <div class="" *ngIf="(jobCardMasterService.linkModeActive$  | async ) === false">
          <div [ngClass]="{'right': !environment.linkJobcardToJcmActive}"  >
            <button
              id="add-new-jc"
              type="button"
              class="btn btn-sm btn-primary mx-2 d-flex flex-row justify-content-center align-items-center"
              [disabled]="jobidCardDetailService.loadingMassiveCreation$ | async"
              (click)="openJobCardModal()">
              <span class="material-icons" aria-hidden="true">add</span>
              {{'jobCardList.addNewJobCard' | translate}}
            </button>
          </div>
        </div>

        <div class="" *ngIf="jobCardMasterService.linkModeActive$ | async ">
          <div >
            <button [disabled]="selectedLines<=0" id="link-jc2jcm-save" type="button" class="btn btn-primary  mx-2 d-flex flex-row justify-content-center align-items-center" (click)="openLinkToJobCardMasterModal()">
              <span class="material-icons" aria-hidden="true">add_link</span>
              {{ 'link ('+selectedLines+')' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="horizontal-bar mb-3"></div>

    <div class="d-flex">
      <div class="container-fluid p-10 ">
      <div class="row justify-content-end"  >
        <button (click)="resetFilter()"
                class="col-2 btn btn-sm btn-tertiary col-sm-auto"
                id="resetFilter" type="button">
            <span class="material-icons">refresh</span>{{'jobCardList.resetFilter' | translate}}

        </button>

      </div>
      </div>
    </div>
    <div class="container-fluid pt-4 d-flex">
      <ag-grid-angular
        #agGrid
        class="ag-theme-alpine jobid-card-list"
        id="job-cards-list"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowModelType]="rowModelType"
        [serverSideStoreType]="'partial'"
        [pagination]="true"
        [rowSelection]="'multiple'"
        [rowMultiSelectWithClick]="false"
        [paginationPageSize]="paginationPageSize"
        [cacheBlockSize]="cacheBlockSize"
        [suppressRowClickSelection]="true"
        [animateRows]="true"
        [rowHeight]="48"
        [rowData]="rowData"
        [isRowSelectable]="isRowSelectable"
        [getRowNodeId]="getRowNodeId"
        (gridReady)="onGridReady($event)"
        [sideBar]="sideBar"
        (rowClicked)="onRowClicked($event)"
        (modelUpdated)="onModelUpdated()"
        (filterChanged)="onFilterChanged($event)"
        (sortChanged)="onSortChanged($event)"
        (selectionChanged)="onSelectionChanged($event)"
        [frameworkComponents]="frameworkComponents"
      ></ag-grid-angular>
    </div>
  </div>
</div>
<ng-template #noAffiliateMessage>
  <div class="container-fluid h-75">
    <h2 class="text-center">Sorry but it seems that you belong to any groups that has access to Job ID card, please
      contact support team or report it in Bug Report Teams channel</h2>
  </div>
</ng-template>

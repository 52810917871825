import { Injectable } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import {
  Material,
  MaterialGlobalStatus,
  MaterialInput,
  MaterialListDTO,
  MaterialListDTOInput,
  MaterialQA_QC_Certificate_Received,
} from "@shared/models/prerequisites/material.model";

import { AbstractArrayFormService } from "../abstract-array-form.service";
import { combineLatest } from "rxjs";
import { distinctUntilChanged, startWith, takeUntil } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { getDateFromString } from "@shared/utils";

@Injectable({
  providedIn: "root",
})
export class MaterialFormService extends AbstractArrayFormService {
  defaultDTO = {};
  jobCardId!: number;
  flag = "";

  get formArrayName() {
    return "materials";
  }

  constructor(protected fb: UntypedFormBuilder, private datePipe: DatePipe) {
    super(fb);
  }

  getFormData(materialListDTO: MaterialListDTO | undefined): MaterialListDTOInput | undefined {
    if (!materialListDTO) {
      return undefined;
    }
    if (!this.form)
      return {
        materialStatus: materialListDTO.materialStatus,
        fatDTO: {
          displayed: materialListDTO.fatDTO?.displayed,
          description: materialListDTO.fatDTO?.description,
          plannedDate: materialListDTO.fatDTO?.plannedDate,
          status: materialListDTO.fatDTO?.status,
          id: materialListDTO.fatDTO?.id,
          jobCardId: materialListDTO.fatDTO?.jobCardId,
        },
        materials: materialListDTO.materials
          ?.filter((material) => !material.sapOrigin)
          .map((material: Material) => this.copy(material)),
      };
    return {
      materialStatus: this.getControl("materialStatus")?.value,
      fatDTO: {
        description: this.getControl("fatDescription")?.value,
        plannedDate: this.datePipe.transform(this.getControl("plannedDate")?.value, "dd/MM/yyyy") ?? "",
        status: this.getControl("fatStatus")?.value,
        displayed: this.getControl("displayed")?.value,
        id: this.getControl("id")?.value,
        jobCardId: this.getControl("jobCardId")?.value,
      },
      materials: this.formArray.getRawValue()
        ? this.formArray.getRawValue().map((material) => ({
            affiliate: material.affiliate,
            eta: this.datePipe.transform(material?.eta, "dd/MM/yyyy") ?? material?.eta,
            id: material.id ?? 0,
            itemNumber: material.itemNumber,
            jobCardId: material.jobCardId,
            materialDescription: material.materialDescription,
            materialNumber: material.materialNumber,
            materialQuantityRequested: material.materialQuantityRequested,
            materialQuantitySite: material.materialQuantitySite,
            procurementStatus: material.procurementStatus,
            qaQcCertificate: material.qaQcCertificate,
            sapOrigin: material.sapOrigin,
            unloadingPoint: material.unloadingPoint,
            workOrderNumber: material.workOrderNumber,
          }))
        : [],
    };
  }

  createFormGroup(material?: Material) {
    const formGroup = this.fb.group({
      affiliate: [material?.affiliate],
      itemNumber: [material?.itemNumber],
      materialNumber: [{ value: material?.materialNumber, disabled: true }, []],
      materialDescription: [
        {
          value: material?.materialDescription,
          disabled: !!material?.sapOrigin,
        },
      ],
      unloadingPoint: [
        {
          value: material?.unloadingPoint,
          disabled: !!material?.sapOrigin,
        },
      ],
      materialQuantityRequested: [{ value: material?.materialQuantityRequested, disabled: !!material?.sapOrigin }],
      materialQuantitySite: [{ value: material?.materialQuantitySite, disabled: !!material?.sapOrigin }],
      procurementStatus: [
        {
          value: material?.procurementStatus,
          disabled: !!material?.sapOrigin,
        },
        [Validators.required],
      ],
      qaQcCertificate: [
        {
          value: material?.qaQcCertificate
            ? material?.qaQcCertificate
            : MaterialQA_QC_Certificate_Received.NOT_PROVIDED,
          disabled: false,
        },
      ],
      eta: [
        {
          value: material?.eta ? getDateFromString(material?.eta?.toString()) : null,
          disabled: !!material?.sapOrigin,
        },
        [Validators.required],
      ],

      sapOrigin: [!!material?.sapOrigin],
      id: [material?.id],
      jobCardId: [this.jobCardId],
      workOrderNumber: [material?.workOrderNumber],
    });

    combineLatest([
      formGroup
        .get("materialQuantityRequested")
        ?.valueChanges.pipe(startWith(formGroup.get("materialQuantityRequested")?.value as string)),
      formGroup
        .get("materialQuantitySite")
        ?.valueChanges.pipe(startWith(formGroup.get("materialQuantitySite")?.value as string)),
    ])
      .pipe(distinctUntilChanged(), takeUntil(this.destroy))
      .subscribe(() => {
        if (this.form.pristine) {
          return;
        }
        this.setMaterialGlobalStatus();
      });

    return formGroup;
  }

  createMaterialForm(materialListDTO?: MaterialListDTO) {
    this.data = materialListDTO;
    const displayed = materialListDTO?.fatDTO?.displayed;
    this.form = this.fb.group({
      fatDescription: [materialListDTO?.fatDTO?.description, displayed ? [Validators.required] : []],
      plannedDate: getDateFromString(materialListDTO?.fatDTO?.plannedDate?.toString() ?? null),
      fatStatus: [materialListDTO?.fatDTO?.status, displayed ? [Validators.required] : []],
      jobCardId: materialListDTO?.fatDTO?.jobCardId ?? this.jobCardId,
      id: materialListDTO?.fatDTO?.id ?? 0,
      displayed: materialListDTO?.fatDTO?.displayed,
    });
    this.form.addControl(this.formArrayName, this.setInitialData(materialListDTO?.materials));
    this.form.addControl("materialStatus", this.fb.control(materialListDTO?.materialStatus));
    this.setMaterialGlobalStatus();
    this.disableRequiredFieldsOnChanges("displayed", ["fatDescription", "fatStatus"]);
    return this.form;
  }

  onItemDelete(index: number) {
    this.removeItem(index);
    this.setMaterialGlobalStatus();
  }

  private setMaterialGlobalStatus() {
    let materialStatus = MaterialGlobalStatus.N_A;

    const allReceived = this.formArray.controls?.reduce(
      (sum, next) => sum && next.get("materialQuantitySite")?.value >= next.get("materialQuantityRequested")?.value,
      true,
    );
    const notReceived = this.formArray.controls?.reduce(
      (sum, next) =>
        sum && next.get("materialQuantitySite")?.value === 0 && next.get("materialQuantityRequested")?.value > 0,
      true,
    );

    if (!(notReceived && allReceived)) {
      if (notReceived) {
        materialStatus = MaterialGlobalStatus.NOT_RECEIVED;
      } else if (allReceived) {
        materialStatus = MaterialGlobalStatus.RECEIVED;
      } else {
        materialStatus = MaterialGlobalStatus.PARTIALLY_RECEIVED;
      }
    }

    if (this.form.get("materialStatus")?.value !== materialStatus) {
      this.form.get("materialStatus")?.patchValue(materialStatus);
    }
  }

  private copy(material: Material): MaterialInput {
    return {
      affiliate: material.affiliate,
      eta: material?.eta,
      id: material.id ?? 0,
      itemNumber: material.itemNumber,
      jobCardId: material.jobCardId,
      materialDescription: material.materialDescription,
      materialNumber: material.materialNumber,
      materialQuantityRequested: material.materialQuantityRequested,
      materialQuantitySite: material.materialQuantitySite,
      procurementStatus: material.procurementStatus,
      qaQcCertificate: material.qaQcCertificate,
      sapOrigin: material.sapOrigin,
      unloadingPoint: material.unloadingPoint,
      workOrderNumber: material.workOrderNumber,
    };
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }
}

import { Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  DocumentDTO
} from "@shared/models/prerequisites/document-dto.model";

@Injectable({
  providedIn: "root",
})
export class DocumentLinkModalFormService {
  documentForm: UntypedFormGroup;

  constructor(protected fb: UntypedFormBuilder){
    this.documentForm = this.fb.group({
      name: ["", [Validators.required]],
      documentLink: ["", [Validators.required]],
    });
  }

  isDocumentValid(){
    return this.documentForm.controls.name.valid && this.documentForm.controls.documentLink.valid;
  }

  getNewDocument(section: string, documentType: string, sectionIndex: number): DocumentDTO{
    const newDocument: DocumentDTO = {
      name: this.documentForm?.get("name")?.value,
      link: this.documentForm?.get("documentLink")?.value,
      section: section,
      type: documentType,
      sectionIndex: sectionIndex
    };

    return newDocument;
  }

  removeLink(i: number, documents: DocumentDTO[]){
    documents[i].name = "";
    documents[i].link = "";
    documents[i].section = "";
    documents[i].sectionIndex = -1;
    documents[i].type = "";
    return documents;
  }

  resetInput(){
    this.documentForm.get("name")?.patchValue("");
    this.documentForm.get("documentLink")?.patchValue("");
  }
}

import { Injectable } from "@angular/core";
import { GeneralDetailsFormService } from "@shared/services/general-details/general-details-form.service";
import { JobScopeFormService } from "@routes/jobid-card/detail/prerequisites/job-scope/job-scope-form.service";
import { ImpactFormService } from "@routes/jobid-card/detail/prerequisites/impact/impact-form.service";
import { MaterialFormService } from "@routes/jobid-card/detail/prerequisites/material/material-form.service";
import { ScaffoldingFormService } from "@routes/jobid-card/detail/prerequisites/resources/scaffolding/scaffolding-form.service";
import { LiftingFormService } from "@routes/jobid-card/detail/prerequisites/resources/lifting/lifting-form.service";
import { OnshoreWorksFormService } from "@routes/jobid-card/detail/prerequisites/resources/onshore-works/onshore-works-form.service";
import { TemporaryEquipmentFormService } from "@routes/jobid-card/detail/prerequisites/resources/temporary-equipment/temporary-equipment-form.service";
import { IsolationFormService } from "@routes/jobid-card/detail/prerequisites/resources/isolation/isolation-form.service";
import { InhibitionFormService } from "@routes/jobid-card/detail/prerequisites/resources/inhibition/inhibition-form.service";
import { CoreCrewAssistanceFormService } from "@routes/jobid-card/detail/prerequisites/resources/core-crew-assistance/core-crew-assistance-form.service";
import { ContractorAssistanceFormService } from "@routes/jobid-card/detail/prerequisites/resources/contractor-assistance/contractor-assistance-form.service";
import { SpecificLogisticFormService } from "@routes/jobid-card/detail/prerequisites/resources/specific-logistic/specific-logistic-form.service";
import { LettersOfInvitationFormService } from "@routes/jobid-card/detail/prerequisites/resources/letters-of-invitation/letters-of-invitation-form.service";
import { JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES } from "@routes/jobid-card/jobid-card.routes";
import { Prerequisites, PrerequisitesInput } from "@shared/models/prerequisites/prerequisites.model";
import { UntypedFormGroup } from "@angular/forms";
import { CustomPrerequisiteFormService } from "@routes/jobid-card/detail/prerequisites/resources/custom-prerequisite/custom-prerequisite-from.service";
import { MobilizationFormService } from "@routes/jobid-card/detail/prerequisites/mobilization/mobilization-form.service";
import { Impact, ImpactInput } from "@shared/models/prerequisites/impact.model";
import { JobScope, JobScopeInput, Scop, ScopInput } from "@shared/models/prerequisites/job-scope.model";
import { MaterialListDTO, MaterialListDTOInput } from "@shared/models/prerequisites/material.model";
import { ResourceDTO, ResourceDTOInput } from "@shared/models/prerequisites/resource-dto.model";
import {
  MobilizationPrerequisites,
  MobilizationPrerequisitesInput,
} from "@shared/models/prerequisites/mobilization-prerequisites.model";
import {
  JobCardGeneralDetailsAggregate,
  JobCardGeneralDetailsAggregateInput,
} from "@shared/models/jobid-card/GeneralDetailsAggregate.model";
import { CalorifugeRemovalFormService } from "@routes/jobid-card/detail/prerequisites/resources/calorifuge-removal/calorifuge-removal-form.service";
import { WorkOrderPrerequisite } from "@shared/models/work-orders/workorder-details.model";
import { CoreCrewAssistanceStatus, Discipline, InhibitionStatus, IsolationStatus, IsolationType, ResourceLiftingDTO, ResourceScaffoldingDTO } from "@shared/models/prerequisites";
import { ResourceCalorifugeRemovalDTO } from "@shared/models/prerequisites/resource-calorifuge-removal.model";

const isFormValid = (form: any) => (form === undefined ? true : form.valid);

@Injectable({
  providedIn: "root",
})
export class JobidCardDetailHelperService {
  formSubmitted = false;

  readonly workOrderPrerequisiteProcessingMap = new Map<WorkOrderPrerequisite, (siteVisitOnly: boolean, prerequisites: Prerequisites) => boolean>([
    [WorkOrderPrerequisite.Isolation, (siteVisitOnly, prerequisites) => !siteVisitOnly && this.addIsolation(prerequisites)],
    [WorkOrderPrerequisite.Inhibition, (siteVisitOnly, prerequisites) => !siteVisitOnly && this.addInhibition(prerequisites)],
    [WorkOrderPrerequisite.Lifting, (siteVisitOnly, prerequisites) => !siteVisitOnly && this.addLifting(prerequisites)],
    [WorkOrderPrerequisite.Scaffolding, (_siteVisitOnly, prerequisites) => this.addScaffolding(prerequisites)],
    [WorkOrderPrerequisite.Calorifuge, (siteVisitOnly, prerequisites) => !siteVisitOnly && this.addCalorifuge(prerequisites)],
    [WorkOrderPrerequisite.CoreCrewAssistance, (_siteVisitOnly, prerequisites) => this.addCoreCrewAssistance(prerequisites)],
    [WorkOrderPrerequisite.ContractorAssistance, (_siteVisitOnly, prerequisites) => this.addContractorAssistance(prerequisites)],
    [WorkOrderPrerequisite.OnshoreWorks, (siteVisitOnly, prerequisites) =>!siteVisitOnly && this.addOnshoreWorks(prerequisites)],
    [WorkOrderPrerequisite.RFMNumber, (_siteVisitOnly, prerequisites) => this.addRfmNumber(prerequisites)],
    [WorkOrderPrerequisite.DivingROV, (_siteVisitOnly, prerequisites) => this.addDivingRov(prerequisites)],
  ]);

  static mapGeneralDetailsToInput(details: JobCardGeneralDetailsAggregate): JobCardGeneralDetailsAggregateInput {
    const {
      actualStatus,
      potentialReadiness,
      seceFromSap,
      generalStatusList,
      lastUpdateReadiness,
      creationDate,
      lastSapSyncDate,
      jobCardMasterId,
      jobCardMasterTitle,
      projectWbsGroupCode,
      parentId,
      isLeader,
      parentTitle,
      ...restDetails
    } = details;

    const input: JobCardGeneralDetailsAggregateInput = {
      ...restDetails,
    };

    return input;
  }

  static mapMaterialListToMaterialListInput(input: MaterialListDTO | undefined): MaterialListDTOInput {
    if (!input) {
      return {} as MaterialListDTOInput;
    }
    const materials = input.materials?.map((material) => {
      const { readiness, ...restMaterial } = material;
      const materialInput = {
        ...restMaterial,
      };
      return materialInput;
    }) ?? [];

    const { readiness, ...restFat } = input.fatDTO ?? {};
    const fatInputDTO = {
      ...restFat,
    };
    return {
      materials: materials,
      fatDTO: fatInputDTO,
      materialStatus: input.materialStatus,
    };
  }

  constructor(
    public generalDetailsFormService: GeneralDetailsFormService,
    public jobScopeFormService: JobScopeFormService,
    public impactFormService: ImpactFormService,
    private materialFormService: MaterialFormService,
    private scaffoldingFormService: ScaffoldingFormService,
    private liftingFormService: LiftingFormService,
    private onshoreWorksFormService: OnshoreWorksFormService,
    private temporaryEquipmentFormService: TemporaryEquipmentFormService,
    private isolationFormService: IsolationFormService,
    private inhibitionFormService: InhibitionFormService,
    private coreCrewAssistanceFormService: CoreCrewAssistanceFormService,
    private contractorAssistanceFormService: ContractorAssistanceFormService,
    private specificLogisticFormService: SpecificLogisticFormService,
    private invitationFormService: LettersOfInvitationFormService,
    private customPrerequisiteService: CustomPrerequisiteFormService,
    private mobilizationsFormService: MobilizationFormService,
    private calorifugeRemovalFormService: CalorifugeRemovalFormService,
  ) {}

  areFormsValid() {
    return (
      isFormValid(this.generalDetailsFormService.form) &&
      isFormValid(this.jobScopeFormService.form) &&
      isFormValid(this.impactFormService.form) &&
      isFormValid(this.materialFormService.form) &&
      isFormValid(this.invitationFormService.form) &&
      isFormValid(this.contractorAssistanceFormService.form) &&
      isFormValid(this.temporaryEquipmentFormService.form) &&
      isFormValid(this.isolationFormService.form) &&
      isFormValid(this.inhibitionFormService.form) &&
      isFormValid(this.liftingFormService.form) &&
      isFormValid(this.onshoreWorksFormService.form) &&
      isFormValid(this.calorifugeRemovalFormService.form) &&
      isFormValid(this.coreCrewAssistanceFormService.form) &&
      isFormValid(this.specificLogisticFormService.form) &&
      isFormValid(this.scaffoldingFormService.form) &&
      isFormValid(this.customPrerequisiteService.form) &&
      isFormValid(this.mobilizationsFormService.form)
    );
  }

  private getFormErrors(form: UntypedFormGroup, sectionName: string) {
    if (!form) {
      return [];
    }
    const invalid = [];
    const controls = form.controls;

    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(sectionName + "/" + name);
      }
    }

    if (form.errors != null) {
      if (form.errors.validateDGS != undefined && !form.errors.validateDGS) {
        invalid.push(sectionName + "/ DGS");
      }

      if (form.errors.validateLOTI != undefined && !form.errors.validateLOTI) {
        invalid.push(sectionName + "/ LOTI/LTI");
      }
      if (form.errors?.wbsElementNotFound != undefined && form.errors?.wbsElementNotFound) {
        invalid.push(sectionName + "/ WBS");
      }
    }

    return invalid;
  }

  getAllFormsErrorsList(): Array<string> {
    return [
      this.getFormErrors(this.generalDetailsFormService.form, "GENERAL DETAILS"),
      this.getFormErrors(this.jobScopeFormService.form, "PREREQUISITES/JOB SCOPE"),
      this.getFormErrors(this.impactFormService.form, "PREREQUISITES/IMPACT"),
      this.getFormErrors(this.materialFormService.form, "PREREQUISITES/MATERIAL"),
      this.getFormErrors(this.invitationFormService.form, "PREREQUISITES/INVITATION"),
      this.getFormErrors(this.contractorAssistanceFormService.form, "PREREQUISITES/CONTRACTOR ASSISTANCE"),
      this.getFormErrors(this.temporaryEquipmentFormService.form, "PREREQUISITES/TEMPORARY EQUIPMENT"),
      this.getFormErrors(this.isolationFormService.form, "PREREQUISITES/ISOLATION"),
      this.getFormErrors(this.inhibitionFormService.form, "PREREQUISITES/INHIBITION"),
      this.getFormErrors(this.liftingFormService.form, "PREREQUISITES/LIFTING"),
      this.getFormErrors(this.onshoreWorksFormService.form, "PREREQUISITES/ONSHORE_WORKS"),
      this.getFormErrors(this.onshoreWorksFormService.form, "PREREQUISITES/CALORIFUGE_REMOVAL"),
      this.getFormErrors(this.coreCrewAssistanceFormService.form, "PREREQUISITES/CORE CREW ASSISTANCE"),
      this.getFormErrors(this.specificLogisticFormService.form, "PREREQUISITES/SPECIFIC LOGISTIC"),
      this.getFormErrors(this.scaffoldingFormService.form, "PREREQUISITES/SCAFFOLDING"),
      this.getFormErrors(this.customPrerequisiteService.form, "PREREQUISITES/CUSTOM PREREQUISITE"),
      this.getFormErrors(this.mobilizationsFormService.form, "PREREQUISITES/MOBILIZATIONS"),
    ].reduce((acc, val) => acc.concat(val), []);
  }

  isPageInvalid(routeName: string): boolean {
    const resourcesIsValid =
      isFormValid(this.invitationFormService.form) &&
      isFormValid(this.contractorAssistanceFormService.form) &&
      isFormValid(this.temporaryEquipmentFormService.form) &&
      isFormValid(this.isolationFormService.form) &&
      isFormValid(this.inhibitionFormService.form) &&
      isFormValid(this.liftingFormService.form) &&
      isFormValid(this.onshoreWorksFormService.form) &&
      isFormValid(this.calorifugeRemovalFormService.form) &&
      isFormValid(this.coreCrewAssistanceFormService.form) &&
      isFormValid(this.specificLogisticFormService.form) &&
      isFormValid(this.scaffoldingFormService.form) &&
      isFormValid(this.customPrerequisiteService.form);

    let isValid = true;
    switch (routeName) {
      case JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.DISCUSSIONS:
        break;
      case JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.GENERAL:
        isValid = isFormValid(this.generalDetailsFormService.form);
        break;
      case JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.JOB_SCOPE:
        isValid = isFormValid(this.jobScopeFormService.form);
        break;
      case JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.IMPACT:
        isValid = isFormValid(this.impactFormService.form);
        break;
      case JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.MATERIAL:
        isValid = isFormValid(this.materialFormService.form);
        break;
      case JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.RESOURCES:
        isValid = resourcesIsValid;
        break;
      case JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.MOBILIZATIONS:
        isValid = isFormValid(this.mobilizationsFormService.form);
        break;
      case JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.HISTORY:
        break;

      default: // PREREQUISITES_DOCUMENTS
        isValid =
          isFormValid(this.jobScopeFormService.form) &&
          isFormValid(this.impactFormService.form) &&
          isFormValid(this.materialFormService.form) &&
          resourcesIsValid &&
          isFormValid(this.mobilizationsFormService.form);
        break;
    }

    return this.formSubmitted && !isValid;
  }

  submitForms(formSubmitted: boolean) {
    const newState = Object.assign({}, { submitted: formSubmitted });
    this.generalDetailsFormService.submittedState = newState;
    this.jobScopeFormService.submittedState = newState;
    this.impactFormService.submittedState = newState;
    this.materialFormService.submittedState = newState;
    this.invitationFormService.submittedState = newState;
    this.contractorAssistanceFormService.submittedState = newState;
    this.temporaryEquipmentFormService.submittedState = newState;
    this.isolationFormService.submittedState = newState;
    this.inhibitionFormService.submittedState = newState;
    this.liftingFormService.submittedState = newState;
    this.onshoreWorksFormService.submittedState = newState;
    this.calorifugeRemovalFormService.submittedState = newState;
    this.coreCrewAssistanceFormService.submittedState = newState;
    this.specificLogisticFormService.submittedState = newState;
    this.scaffoldingFormService.submittedState = newState;
    this.customPrerequisiteService.submittedState = newState;
    this.mobilizationsFormService.submittedState = newState;
    this.formSubmitted = formSubmitted;
  }

  constructPrerequisitesInputFromForms(prerequisites: Prerequisites): PrerequisitesInput {
    const jobScopeDTO = this.jobScopeFormService.getFormData(prerequisites.jobScopeDTO);
    const jobScopeDocuments = this.jobScopeFormService.getFormDocuments(prerequisites.documentDTOList);
    const impactDTO = this.impactFormService.getFormData(prerequisites.impactDTO);
    const impactDocuments = this.impactFormService.getFormDocuments(prerequisites.documentDTOList);
    const materialListDTO = this.materialFormService.getFormData(prerequisites.materialListDTO);
    const resourceContractorList = this.contractorAssistanceFormService.getFormData(
      prerequisites.resourceDTO.resourceContractorListDTO,
    );
    const invitation = this.invitationFormService.getFormData(prerequisites.resourceDTO.resourceInvitationDTO);
    const scaffoldingList = this.scaffoldingFormService.getFormData(
      prerequisites.resourceDTO.resourceScaffoldingListDTO,
    );
    const scaffoldingDocuments = this.scaffoldingFormService.getFormDocuments(prerequisites.documentDTOList);
    const liftingList = this.liftingFormService.getFormData(prerequisites.resourceDTO.resourceLiftingListDTO);
    const liftingDocuments = this.liftingFormService.getFormDocuments(prerequisites.documentDTOList);
    const onshoreWorksList = this.onshoreWorksFormService.getFormData(
      prerequisites.resourceDTO.resourceOnshoreWorksDTO,
    );
    const calorigugeDocuments = this.calorifugeRemovalFormService.getFormDocuments(prerequisites.documentDTOList);
    const calorifugeList = this.calorifugeRemovalFormService.getFormData(
      prerequisites.resourceDTO.resourceCalorifugeRemovalListDTO,
    );
    const onshoreWorksDocuments = this.onshoreWorksFormService.getFormDocuments(prerequisites.documentDTOList);
    const temporaryEquipmentList = this.temporaryEquipmentFormService.getFormData(
      prerequisites.resourceDTO.resourceTemporaryEquipmentListDTO,
    );
    const isolationList = this.isolationFormService.getFormData(prerequisites.resourceDTO.resourceIsolationListDTO);
    const inhibitionList = this.inhibitionFormService.getFormData(prerequisites.resourceDTO.resourceInhibitionListDTO);
    const coreCrewAssistanceList = this.coreCrewAssistanceFormService.getFormData(
      prerequisites.resourceDTO.resourceCoreCrewAssistanceListDTO,
    );
    const logisticList = this.specificLogisticFormService.getFormData(
      prerequisites.resourceDTO.resourceLogisticListDTO,
    );
    const customPrerequisiteList = this.customPrerequisiteService.getFormData(
      prerequisites.resourceDTO.resourceCustomPrerequisiteListDTO,
    );
    const customPrerequisiteDocuments = this.customPrerequisiteService.getFormDocuments(prerequisites.documentDTOList);
    const mobilizationsPrerequisites = this.mobilizationsFormService.getFormData(prerequisites.mobilizationsDTO);
    return {
      jobId: prerequisites.jobId,
      jobScopeDTO,
      impactDTO: impactDTO,
      materialListDTO,
      documentDTOList: [
        impactDocuments,
        jobScopeDocuments,
        scaffoldingDocuments,
        liftingDocuments,
        onshoreWorksDocuments,
        calorigugeDocuments,
        customPrerequisiteDocuments,
      ].reduce((acc, val) => acc.concat(val), []),
      resourceDTO: {
        resourceScaffoldingListDTO: scaffoldingList,
        resourceLiftingListDTO: liftingList,
        resourceOnshoreWorksDTO: onshoreWorksList,
        resourceTemporaryEquipmentListDTO: temporaryEquipmentList,
        resourceIsolationListDTO: isolationList,
        resourceInhibitionListDTO: inhibitionList,
        resourceContractorListDTO: resourceContractorList,
        resourceCoreCrewAssistanceListDTO: coreCrewAssistanceList,
        resourceLogisticListDTO: logisticList,
        resourceInvitationDTO: invitation,
        resourceCustomPrerequisiteListDTO: customPrerequisiteList,
        resourceCalorifugeRemovalListDTO: calorifugeList,
      },
      mobilizationsDTO: mobilizationsPrerequisites,
    };
  }

  mapImpactToImpactInput(input: Impact | undefined): ImpactInput {
    if (!input) {
      return {} as ImpactInput;
    }
    const {
      readiness,
      divingRovReadiness,
      hydroPressureTestReadiness,
      confinedSpaceReadiness,
      hazardousClassifiedAreaReadiness,
      permitToWorkReadiness,
      shortFallImpactReadiness,
      regulatorApprovalReadiness,
      radiographyReadiness,
      simopsReadiness,
      ...rest
    } = input;
    return {
      ...rest,
    };
  }

  mapJobScopeToJobScopeInput(input: JobScope | undefined): JobScopeInput {
    if (!input) {
      return {} as JobScopeInput;
    }
    const {
      readiness,
      methodStatementReadiness,
      wellInterventionReadiness,
      komReadiness,
      rfmNumberReadiness,
      ...rest
    } = input;
    const scopInput = this.mapScopToScopInput(rest.scop);
    return {
      ...rest,
      scop: scopInput
    };
  }

  mapScopToScopInput(scop: Scop | undefined): ScopInput | undefined {
    if (!scop) {
      return undefined;
    }
    const {readiness, ...scopInput} = scop;
    return scopInput;
  }




  mapResourceListToInput<T, U>(input: T[] | undefined): U[] {
    if (!input) {
      return [] as U[];
    }
    return input.map((item) => {
      const { readiness, ...restItem } = item as any;
      const itemInput = {
        ...restItem,
      };
      return itemInput;
    });
  }

  mapResourceToResourceInput(input: ResourceDTO | undefined): ResourceDTOInput {
    return {
      resourceContractorListDTO: this.mapResourceListToInput(input?.resourceContractorListDTO),
      resourceCoreCrewAssistanceListDTO: this.mapResourceListToInput(input?.resourceCoreCrewAssistanceListDTO),
      resourceCustomPrerequisiteListDTO: this.mapResourceListToInput(input?.resourceCustomPrerequisiteListDTO),
      resourceInhibitionListDTO: this.mapResourceListToInput(input?.resourceInhibitionListDTO),
      resourceInvitationDTO: this.mapResourceListToInput(input?.resourceInvitationDTO),
      resourceIsolationListDTO: this.mapResourceListToInput(input?.resourceIsolationListDTO),
      resourceLiftingListDTO: this.mapResourceListToInput(input?.resourceLiftingListDTO),
      resourceLogisticListDTO: this.mapResourceListToInput(input?.resourceLogisticListDTO),
      resourceScaffoldingListDTO: this.mapResourceListToInput(input?.resourceScaffoldingListDTO),
      resourceTemporaryEquipmentListDTO: this.mapResourceListToInput(input?.resourceTemporaryEquipmentListDTO),
      resourceOnshoreWorksDTO: this.mapResourceListToInput(input?.resourceOnshoreWorksDTO),
      resourceCalorifugeRemovalListDTO: this.mapResourceListToInput(input?.resourceCalorifugeRemovalListDTO),
    };
  }

  mapMobilizationsToMobilizationsInput(input: MobilizationPrerequisites | undefined): MobilizationPrerequisitesInput {
    if (!input) {
      return {} as MobilizationPrerequisitesInput;
    }
    const { resourcesManagementReadiness, ...rest } = input;
    return {
      ...rest,
    };
  }

  mapPrerequisitesToPrerequisitesInput(prerequisites: Prerequisites): PrerequisitesInput {
    return {
      jobId: prerequisites.jobId,
      jobScopeDTO: this.mapJobScopeToJobScopeInput(prerequisites.jobScopeDTO),
      impactDTO: this.mapImpactToImpactInput(prerequisites.impactDTO),
      materialListDTO: JobidCardDetailHelperService.mapMaterialListToMaterialListInput(prerequisites.materialListDTO),
      documentDTOList: prerequisites.documentDTOList,
      resourceDTO: this.mapResourceToResourceInput(prerequisites.resourceDTO),
      mobilizationsDTO: this.mapMobilizationsToMobilizationsInput(prerequisites.mobilizationsDTO),
    };
  }

  selectPrerequisites(workOrderPrerequisites: WorkOrderPrerequisite[], prerequisites: Prerequisites, siteVisitOnly: boolean): boolean {
    let itemAdded = false;
    workOrderPrerequisites.forEach((prerequisite) => {
      if (this.workOrderPrerequisiteProcessingMap.get(prerequisite)?.(siteVisitOnly, prerequisites)) {
        itemAdded = true;
      }
    });
    return itemAdded;
  }

  private addInhibition(prerequisites: Prerequisites): boolean {
    const inhibitionDisplayed = prerequisites.resourceDTO.resourceInhibitionListDTO.some((inhibition) => inhibition.displayed);
    if (!inhibitionDisplayed) {
      const newInhibition = { displayed: true, id: undefined, readiness: undefined, description: '', equipmentTag: '', status: InhibitionStatus.NOT_STARTED, jobCardId: prerequisites.jobId };
      prerequisites.resourceDTO.resourceInhibitionListDTO.push(newInhibition);
      return true;
    }
    return false;
  }

  private addIsolation(prerequisites: Prerequisites): boolean {
    const isolationDisplayed = prerequisites.resourceDTO.resourceIsolationListDTO.some((item) => item.displayed);
    if (!isolationDisplayed) {
      const newIsolation = { displayed: true, id: undefined, status: IsolationStatus.NOT_STARTED, isolationType: IsolationType.PROCESS, description: '', readiness: undefined, jobCardId: prerequisites.jobId };
      prerequisites.resourceDTO.resourceIsolationListDTO.push(newIsolation);
      return true;
    }
    return false;
  }

  private addLifting(prerequisites: Prerequisites): boolean {
    const itemDisplayed = prerequisites.resourceDTO.resourceLiftingListDTO.some((item) => item.displayed);
    if (!itemDisplayed) {
      const newItem: ResourceLiftingDTO = { displayed: true, id: undefined, readiness: undefined, jobCardId: prerequisites.jobId, slsRequired: false, craneDescription: '', craneRequired: false, liftingProcedureRequired: false };
      prerequisites.resourceDTO.resourceLiftingListDTO.push(newItem);
      return true;
    }
    return false;
  }

  private addScaffolding(prerequisites: Prerequisites): boolean {
    const itemDisplayed = prerequisites.resourceDTO.resourceScaffoldingListDTO.some((item) => item.displayed);
    if (!itemDisplayed) {
      const newItem: ResourceScaffoldingDTO = { displayed: true, id: undefined, readiness: undefined, jobCardId: prerequisites.jobId};
      prerequisites.resourceDTO.resourceScaffoldingListDTO.push(newItem);
      return true;
    }
    return false;
  }

  private addCalorifuge(prerequisites: Prerequisites): boolean {
    const itemDisplayed = prerequisites.resourceDTO.resourceCalorifugeRemovalListDTO.some((item) => item.displayed);
    if (!itemDisplayed) {
      const newItem: ResourceCalorifugeRemovalDTO = { displayed: true, id: undefined, jobCardId: prerequisites.jobId, calorifugeRemovalNeeded: false, equipmentTagAndDescription: ''};
      prerequisites.resourceDTO.resourceCalorifugeRemovalListDTO.push(newItem);
      return true;
    }
    return false;
  }

  private addCoreCrewAssistance(prerequisites: Prerequisites): boolean {
    const itemDisplayed = prerequisites.resourceDTO.resourceCoreCrewAssistanceListDTO.some((item) => item.displayed);
    if (!itemDisplayed) {
      const newItem = { displayed: true, id: undefined, readiness: undefined, jobCardId: prerequisites.jobId, description: '', status: CoreCrewAssistanceStatus.NOT_STARTED, discipline: Discipline.ELEC };
      prerequisites.resourceDTO.resourceCoreCrewAssistanceListDTO.push(newItem);
      return true;
    }
    return false;
  }

  private addContractorAssistance(prerequisites: Prerequisites): boolean {
    const itemDisplayed = prerequisites.resourceDTO.resourceContractorListDTO.some((item) => item.displayed);
    if (!itemDisplayed) {
      const newItem = { displayed: true, id: undefined, jobCardId: prerequisites.jobId, readiness: undefined };
      prerequisites.resourceDTO.resourceContractorListDTO.push(newItem);
      return true;
    }
    return false;
  }

  private addOnshoreWorks(prerequisites: Prerequisites): boolean {
    const itemDisplayed = prerequisites.resourceDTO.resourceOnshoreWorksDTO.some((item) => item.displayed);
    if (!itemDisplayed) {
      const newItem = { displayed: true, id: undefined, jobCardId: prerequisites.jobId, readiness: undefined, excavationRequired: false, pipesAndCableIdentificationRequired: false, grassCuttingRequired: false, securityBridgingDocumentRequired: false, communityEngagementThroughCAPDRequired: false };
      prerequisites.resourceDTO.resourceOnshoreWorksDTO.push(newItem);
      return true;
    }
    return false;
  }

  private addRfmNumber(prerequisites: Prerequisites): boolean {
    if (!prerequisites.jobScopeDTO?.rfmNumber) {
      if (!prerequisites.jobScopeDTO) {
        prerequisites.jobScopeDTO = {id: undefined, rfmNumber: true, methodStatement: false, methodStatementStatus: '', komJra: false, aroRloRequired: '', rfmNumberValue: '', rfmTitle: '', rfmClass: 0, rfmEngineeringStatus: '', wellIntervention: false, wellInterventionStatus: '', costBudget: false, yearlyBudgetRequired: false, describeActions: '', scop: undefined, rfmNumberReadiness: '', methodStatementReadiness: '', komReadiness: '', wellInterventionReadiness: '', readiness: ''};
      } else {
        prerequisites.jobScopeDTO.rfmNumber = true;
      }
      return true;
    }
    return false;
  }

  private addDivingRov(prerequisites: Prerequisites): boolean {
    if (!prerequisites.impactDTO?.impactDivingRov) {
      if (!prerequisites.impactDTO) {
        prerequisites.impactDTO = {
          id: undefined,
          impactSimops: false,
          impactSimopsStatus: '',
          impactShortfall: false,
          impactShortfallProduction: null,
          impactShortfallInjection: null,
          impactShortfallFlaring: null,
          impactPermitToWork: true,
          impactPermitsStatus: 'Not Started',
          impactShutdown: false,
          impactFlaring: false,
          impactHotWork: '',
          impactConfinedSpace: false,
          impactConfinedSpaceProtocolStatus: '',
          impactRadiography: false,
          impactRadiographyStatus: '',
          impactDivingRov: true,
          impactDivingRovStatus: 'Pending',
          impactHydroPressure: false,
          impactHydroPressureStatus: '',
          impactHazardous: false,
          impactHazardousClassifiedArea: '',
          impactHazardousProcedureStatus: '',
          readiness: undefined,
          simopsReadiness: undefined,
          shortFallImpactReadiness: undefined,
          permitToWorkReadiness: undefined,
          confinedSpaceReadiness: undefined,
          radiographyReadiness: undefined,
          divingRovReadiness: undefined,
          hydroPressureTestReadiness: undefined,
          hazardousClassifiedAreaReadiness: undefined,
          regulatorApprovalReadiness: undefined,
          regulatorApproval: false,
          regulatorApprovalDesc: undefined,
          regulatorApprovalStatus: undefined,
          greenHouseGas: false,
          ghgYearlyBudgetRequired: false,
          describeActions: ''
        };
      } else {
        prerequisites.impactDTO.impactPermitToWork = true;
        prerequisites.impactDTO.impactPermitsStatus = 'Not started';
        prerequisites.impactDTO.impactDivingRov = true;
        prerequisites.impactDTO.impactDivingRovStatus = 'Pending';
      }
      return true;
    }
    return false;
  }

}

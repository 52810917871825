import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JobCardMasterApiService } from "@shared/services/job-card-master/job-card-master-api.service";
import { AddNewJobCardMasterModalComponent } from "@shared/components/modal/add-new-job-card-master-modal/add-new-job-card-master-modal.component";
import { ViewMode } from "@shared/services/jobid-card-detail/jobid-card-detail.service";
import { BehaviorSubject, filter, Observable, of, Subject, take } from "rxjs";
import {
  ComputedJobCardCountByStatus,
  JobCardMasterAggregate,
  MassiveLinkReportDTO,
} from "@shared/models/job-card-master/job-card-master.model";
import { catchError, combineLatestWith, distinctUntilChanged, finalize, map } from "rxjs/operators";

import { JCMGeneralDetailsFormService } from "@shared/services/job-card-master/details/jcm-general-details-form.service";
import { AuthService, ProfileService } from "@shared/services";
import { ToastrService } from "ngx-toastr";
import { LinkJobCardToJobCardMasterComponent } from "@shared/components/modal/link-job-card-to-job-card-master/link-job-card-to-job-card-master.component";
import { GraphQLError } from "graphql/error";
import { AppYesNoModalComponent } from "@shared/components/modal/app-yes-no-modal/app-yes-no-modal.component";
import { JOB_CARD_MASTER_CREATION_MUTATION } from "@graphql/job-card-master/mutation/job-card-master-creation-mutation.gql";
import { Apollo } from "apollo-angular";
import { GET_JCM_GENERAL_DETAILS_QUERY } from "@graphql/job-card-master/queries/general-details.gql";
import { LinkJobCardToJobCardMasterByRevcodeComponent } from "@shared/components/modal/link-job-card-to-job-card-master-by-revcode/link-job-card-to-job-card-master-by-revcode.component";
import { JobCardOrganization } from "@shared/models/job-card-master/organization/organization.model";
import { ColumnState } from "@ag-grid-community/core/dist/cjs/columns/columnModel";
import { JOB_CARD_DEPENDENCIES_MUTATION } from "@graphql/job-card-master/mutation/job-card-dependencies-mutation.gql";

@Injectable({
  providedIn: "root",
})
export class JobCardMasterService {
  defaultSizePerPage = this.apiService.defaultSizePerPage;
  private _linkModeActive = false;
  private _linkModeActive$ = new BehaviorSubject(this._linkModeActive);
  linkModeActive$ = this._linkModeActive$.asObservable();

  private _computeJobCardCountByStatus!: ComputedJobCardCountByStatus;
  private _computeJobCardCountByStatus$: BehaviorSubject<ComputedJobCardCountByStatus> =
    new BehaviorSubject<ComputedJobCardCountByStatus>(this._computeJobCardCountByStatus);
  computeJobCardCountByStatus$: Observable<ComputedJobCardCountByStatus> =
    this._computeJobCardCountByStatus$.asObservable();
  private _viewMode: ViewMode = "view"; // "edit"
  private viewMode$: BehaviorSubject<ViewMode> = new BehaviorSubject<ViewMode>(this._viewMode);
  _generalDetails!: JobCardMasterAggregate;
  public generalDetails$: BehaviorSubject<JobCardMasterAggregate> = new BehaviorSubject<JobCardMasterAggregate>(
    this._generalDetails,
  );
  private affiliate!: string;
  private _currentId!: string;
  private updateSuccessful = true;

  filterDataModel!: { [p: string]: any };
  columnState!: ColumnState[];

  isModeReadOnly$: Observable<boolean> = this.viewMode$.pipe(
    distinctUntilChanged(),
    map((viewMode: ViewMode) => viewMode === "view"),
  );

  private readonly EMPTY_LINK_CONTEXT: JobCardLinkOperationContext = {
    linkDestinationJobCardMaster: null,
    jobCardIdsToLinkList: [],
  };
  private readonly EMPTY_UNLINK_CONTEXT: JobCardUnLinkOperationContext = {
    jobCardIdsToUnlinkList: [],
  };

  private _currentLinkContext: JobCardLinkOperationContext = Object.assign({}, this.EMPTY_LINK_CONTEXT);

  private _linkContext$: BehaviorSubject<JobCardLinkOperationContext> =
    new BehaviorSubject<JobCardLinkOperationContext>(this._currentLinkContext);
  linkContext$: Observable<JobCardLinkOperationContext> = this._linkContext$.asObservable();

  private _currentUnLinkContext: JobCardUnLinkOperationContext = Object.assign({}, this.EMPTY_UNLINK_CONTEXT);

  private _unlinkContext$: BehaviorSubject<JobCardUnLinkOperationContext> =
    new BehaviorSubject<JobCardUnLinkOperationContext>(this._currentUnLinkContext);
  public unlinkContext$: Observable<JobCardUnLinkOperationContext> = this._unlinkContext$.asObservable();
  public jcmKpis$: BehaviorSubject<JobCardMasterKPIs> = new BehaviorSubject<JobCardMasterKPIs>({
    jobCardMasterImpactKPISitePlateform: [],
    jobCardMasterImpactKPIDisciplineCategory: [],
    jobCardMasterKPIMasterClassification: [],
  });
  public jcmHighlights$: BehaviorSubject<JobCardMasterHighlights> = new BehaviorSubject<JobCardMasterHighlights>({
    scaffoldingConflicts: [],
    liftingConflicts: [],
    isolationConflicts: [],
    inhibitionConflicts: [],
    temporaryEquipmentConflicts: [],
  });
  public userIsJcmOwner$: Observable<boolean>;
  jobCardsOrganization$ = new Subject<JobCardOrganization[]>();
  public linkIsAllowed$: Observable<boolean>;

  constructor(
    private apiService: JobCardMasterApiService,
    private modalService: NgbModal,
    private generalDetailsFormService: JCMGeneralDetailsFormService,
    private apollo: Apollo,
    private profileService: ProfileService,
    private toastrService: ToastrService,
    private formService: JCMGeneralDetailsFormService,
    private authService: AuthService,
  ) {
    this.subscribeAffiliateOnChange();
    this.linkContext$.subscribe((linkContext) => {
      this._currentLinkContext = linkContext;
    });
    this.userIsJcmOwner$ = this.authService.userName$.pipe(
      combineLatestWith(this.generalDetails$),
      map(([userName, generalDetails]) => {
        return userName === generalDetails?.owner;
      }),
    );
    this.linkIsAllowed$ = this.userIsJcmOwner$.pipe(
      combineLatestWith(
        this.generalDetails$.pipe(
          filter(Boolean),
          map((gen) => gen.isScopeFrozen || false),
        ),
      ),
      map(([isOwner, isScopeFrozen]) => {
        return !isScopeFrozen || isOwner;
      }),
    );
  }

  resetLinkContext() {
    this._currentLinkContext.linkDestinationJobCardMaster = null;
    this._linkContext$.next(this.EMPTY_LINK_CONTEXT);
  }

  resetUnlinkContext() {
    this._unlinkContext$.next(this.EMPTY_UNLINK_CONTEXT);
  }

  updateJobCardListToUnlink(jobCardIdsToUnlinkList: string[] = []) {
    this._currentUnLinkContext.jobCardIdsToUnlinkList = jobCardIdsToUnlinkList;
    this._unlinkContext$.next(this._currentUnLinkContext);
  }

  updateLinkDestination(linkDestinationJobCardMaster: { title: string; id: string } | null) {
    this._currentLinkContext.linkDestinationJobCardMaster = linkDestinationJobCardMaster;
    this._linkContext$.next(this._currentLinkContext);
  }

  updateJobCardListToLink(jobCardIdsToLinkList: string[] = []) {
    this._currentLinkContext.jobCardIdsToLinkList = jobCardIdsToLinkList;
    this._linkContext$.next(this._currentLinkContext);
  }

  createJobCardMasterModal() {
    this.modalService.open(AddNewJobCardMasterModalComponent, { centered: true });
  }

  createLinkJobCardToJobCardMasterModal() {
    return this.modalService.open(LinkJobCardToJobCardMasterComponent, { centered: true });
  }

  createLinkJobCardToJobCardMasterByRevCodePopup() {
    this.modalService.open(LinkJobCardToJobCardMasterByRevcodeComponent, { centered: true });
  }

  getDatasource() {
    return this.apiService.getDatasource();
  }

  changeViewMode(newViewMode: ViewMode) {
    this._viewMode = newViewMode;
    this.viewMode$.next(this._viewMode);
  }

  loadGeneralDetails(id: string, affiliate: string): void {
    this._currentId = id;
    this.getGeneralDetailsOfJobCardMaster(Number(id), affiliate);
  }

  getGeneralDetailsOfJobCardMaster(id: number, affiliate: string): void {
    this.apollo
      .query<any>({
        query: GET_JCM_GENERAL_DETAILS_QUERY,
        variables: {
          affiliate: affiliate,
          id: id,
        },
      })
      .subscribe(({ data }) => {
        this._generalDetails = data.getJobCardMasterById;
        this.generalDetails$.next(data.getJobCardMasterById);
      });
  }

  loadJobCardMasterKpis(id: number, affiliate: string): void {
    this.apiService.loadJCMKpis(affiliate, id).subscribe(({ data }) => {
      this.jcmKpis$.next(data.getJobCardMasterKPIs);
    });
  }

  loadJobCardMasterHighlights(id: number, affiliate: string): void {
    this.apiService.loadJCMHighlights(affiliate, id).subscribe(({ data }) => {
      this.jcmHighlights$.next(data.getOverlappingConflicts);
    });
  }

  loadJobCardOrganization(id: number, affiliate: string): void {
    this.apiService.loadJobCardOrganization(affiliate, id).subscribe(({ data }) => {
      this.jobCardsOrganization$.next(data.fetchAllJobCardsOrganization);
    });
  }

  cancelChange() {
    return AppYesNoModalComponent.open(
      {
        title: "Cancel job card master edition",
        content: "You are about to cancel job card master edition. All changes will be lost.",
        yesLabel: "Confirm",
        noLabel: "Cancel",
      },
      this.modalService,
    ).subscribe((modalResult: boolean) => {
      if (modalResult) {
        this.generalDetails$.next({ ...this._generalDetails });
        this.changeViewMode("view");
      }
    });
  }

  editJobCardMaster() {
    return AppYesNoModalComponent.open(
      {
        title: "Validate job card master edition",
        content: "You are going to validate job card master edition. All changes will be saved.",
        yesLabel: "Validate",
        noLabel: "Cancel",
      },
      this.modalService,
    ).subscribe((modalResult: boolean) => {
      if (modalResult) {
        if (this.validateForm()) {
          this.performUpdate()
            .pipe(
              finalize(() => {
                if (this.updateSuccessful) {
                  const message = `All data loaded for jcmId #${this._currentId}`;
                  const title = "Success";
                  this.toastrService.success(message, title);
                }
                this.closeEditMode();
              }),
            )
            .subscribe({
              next: (data) => {
                this.refreshData(data);
              },
              error: (e: [GraphQLError]) => {
                this.toastrService.error(
                  `Error saving jcm-Id #${this._currentId}: ${e.find((e) => !!e)?.message}`,
                  "Error",
                );
                this.updateSuccessful = false;
              },
            });
        }
      } else {
        this.closeEditMode();
      }
    });
  }

  private performUpdate() {
    const gQLCallresult = new Subject<any>();
    const generalDetailsInput = this.generalDetailsFormService.getFormData(this._generalDetails);
    this.apollo
      .mutate<any>({
        mutation: JOB_CARD_MASTER_CREATION_MUTATION,
        variables: {
          affiliate: this.affiliate,
          input: generalDetailsInput,
        },
        errorPolicy: "all",
      })
      .subscribe(({ data, errors }) => {
        if (errors) {
          gQLCallresult.error(errors);
          gQLCallresult.complete();
        } else {
          gQLCallresult.next(data.createJobCardMaster);
          gQLCallresult.complete();
        }
      });
    return gQLCallresult.asObservable();
  }

  getJobCardMasterLinkedAllowed() {
    return this.apiService
      .getJobCardMasterLinkedAllowed()
      .pipe(
        map((result) =>
          result.data.getJobCardMasterLinkedAllowed.map((jcm) => ({ id: String(jcm.id), title: jcm.title })),
        ),
      );
  }

  subscribeAffiliateOnChange(): void {
    this.profileService.affiliate$.subscribe((affiliate) => {
      if (this.affiliate !== affiliate?.value) {
        this.resetLinkContext();
        this.resetUnlinkContext();
      }
      this.affiliate = affiliate.value;
    });
  }

  private closeEditMode() {
    this.changeViewMode("view");
  }

  private refreshData(data: any) {
    this.generalDetails$.next(data);
  }

  private validateForm() {
    if (!this.formService.isFormValid()) {
      let message = "Compulsory fields with error:";
      message += this.formService.getAllFormsErrorsList().reduce((acc, val) => acc.concat("<br>- " + val), "");
      this.toastrService.error(message, "Error");
      return false;
    }
    return true;
  }

  linkJobCardsToJobCardMaster() {
    const jcmId = this._currentLinkContext?.linkDestinationJobCardMaster?.id;
    return this.apiService
      .linkJobCardsToJobCardMaster(this._currentLinkContext.jobCardIdsToLinkList, jcmId || "-1")
      .pipe(
        map((result) => {
          if (result.errors) {
            this.toastrService.error(
              `Error linking job cards to job card master: ${result.errors[0].message}`,
              "Error",
            );
            return false;
          }
          this.showToastr(jcmId);
          return true;
        }),
      );
  }

  private showToastr(jcmId: string | undefined) {
    this.apiService
      .loadJCMHighlights(this.profileService.getAffiliate().value, Number(jcmId))
      .pipe(take(1))
      .subscribe((result) => {
        const conflicts = result.data.getOverlappingConflicts;
        if (
          conflicts.scaffoldingConflicts.length > 0 ||
          conflicts.liftingConflicts.length > 0 ||
          conflicts.isolationConflicts.length > 0 ||
          conflicts.inhibitionConflicts.length > 0 ||
          conflicts.temporaryEquipmentConflicts.length > 0
        ) {
          const link = `/job-card-master/detail/${jcmId}/highlights`;
          this.toastrService.warning(`Overlap have been detected. <a href=${link}> See details </a>`, "", {
            disableTimeOut: true,
            enableHtml: true,
          });
        } else {
          this.toastrService.success(`Job cards linked to job card master`, "");
        }
      });
  }

  unlinkJobCardsToJobCardMaster() {
    AppYesNoModalComponent.open(
      {
        title: "Validate job card edition",
        content: "You are going to validate job card edition. All changes will be saved.",
        yesLabel: "Validate",
        noLabel: "Cancel",
      },
      this.modalService,
    ).subscribe((modalResult: boolean) => {
      if (modalResult) {
        return this.apiService
          .unlinkJobCardsToJobCardMaster(
            this._currentUnLinkContext.jobCardIdsToUnlinkList,
            this._generalDetails?.id ?? "-1",
          )
          .subscribe((result) => {
            if (result.errors) {
              this.toastrService.error(
                `Error unlinking job cards to job card master: ${result.errors[0].message}`,
                "Error",
              );
              return false;
            } else {
              this.toastrService.success(`Job cards unlinked to job card master`, "Success");
              this.resetUnlinkContext();
              this.loadGeneralDetails(
                String(this._generalDetails?.id) || "-1",
                this.profileService.getAffiliate().value,
              );
              return true;
            }
          });
      } else {
        return false;
      }
    });
  }

  getComputeJobCardCountByStatus(affiliate: string, masterId: number | undefined) {
    this.apiService.computeJCMCounts(affiliate, masterId).subscribe(({ data }) => {
      this._computeJobCardCountByStatus = data.getComputedJobCardMasterKpiAggregate;
      this._computeJobCardCountByStatus$.next(this._computeJobCardCountByStatus);
    });
  }

  public openJobCardListToLinkToJobCardMaster() {
    this.resetLinkContext();
    this.updateLinkDestination({ title: this._generalDetails.title ?? "", id: String(this._generalDetails.id ?? "") });
    this.activeLinkMode(true);
  }

  public activeLinkMode(active: boolean) {
    if (!active) {
      this.resetLinkContext();
    }
    this._linkModeActive$.next(active);
  }

  public linkJcByRevisionCode(revisionCode: string): Observable<{
    massiveLinkReport: MassiveLinkReportDTO | undefined;
    massiveLinkReportError: string | undefined;
  }> {
    const mappedResult: {
      massiveLinkReport: MassiveLinkReportDTO | undefined;
      massiveLinkReportError: string | undefined;
    } = {
      massiveLinkReport: undefined,
      massiveLinkReportError: undefined,
    };
    return this.apiService.linkJobCardToJobCardMasterByRevcode(this.affiliate, this._currentId, revisionCode).pipe(
      map((result) => {
        if (result?.data?.linkByRevisionCode) {
          mappedResult.massiveLinkReport = result?.data?.linkByRevisionCode;
          this.loadGeneralDetails(String(this._generalDetails?.id) || "-1", this.profileService.getAffiliate().value);
        } else {
          mappedResult.massiveLinkReportError = result.errors?.[0]?.message ?? "Unknown error";
        }
        return mappedResult;
      }),
      catchError((error) => {
        mappedResult.massiveLinkReportError = error.message;
        return of(mappedResult);
      }),
    );
  }

  saveJobCardOrganization(organization: JobCardOrganization) {
    const jobCardDependenciesInput: { id: number; dependencies: number[] } = {
      id: organization.id,
      dependencies: organization.dependencies?.map((d) => d.id) ?? [],
    };
    return this.apollo
      .mutate({
        mutation: JOB_CARD_DEPENDENCIES_MUTATION,
        variables: {
          affiliate: this.affiliate,
          input: jobCardDependenciesInput,
        },
        errorPolicy: "all",
      })
      .subscribe((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        } else {
          return;
        }
      });
  }

  freezeJcm(currentJcmId: number) {
    return this.apiService.freezeJcm(currentJcmId);
  }
}

export type JobCardLinkOperationContext = {
  linkDestinationJobCardMaster: { title: string; id: string } | null;
  jobCardIdsToLinkList: string[];
};

export type JobCardUnLinkOperationContext = {
  jobCardIdsToUnlinkList: string[];
};

export interface Classification {
  label: string;
  count: number;
  countReady: number;
  countAwaitingPreparation: number;
  countDelayed: number;
  countReadyToActivate: number;
  countDraft: number;
}

export enum ClassificationLabels {
  DRIVER = "DRIVER",
  BUILDING_BLOCK = "BUILDING_BLOCK",
  OPPORTUNITY = "OPPORTUNITY",
  NOT_DEFINED = "NOT DEFINED",
}

export type JobCardMasterKPIs = {
  jobCardMasterImpactKPISitePlateform: { label: string; count: number }[];
  jobCardMasterImpactKPIDisciplineCategory: { label: string; count: number }[];
  jobCardMasterKPIMasterClassification: Classification[];
};

export type JobCardMasterHighlights = {
  scaffoldingConflicts: { id: number; jobTitle: string }[];
  liftingConflicts: { id: number; jobTitle: string }[];
  isolationConflicts: { id: number; jobTitle: string }[];
  inhibitionConflicts: { id: number; jobTitle: string }[];
  temporaryEquipmentConflicts: { id: number; jobTitle: string }[];
};

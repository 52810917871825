import gql from "graphql-tag";

const GET_GENERAL_DETAILS_QUERY = gql`
  query ($affiliate: String, $id: Int) {
    jobCardGeneralDetailsAggregated(affiliate: $affiliate, id: $id) {
      id
      jobTitle
      priority
      workOrderNumber
      description
      generalStatus
      actualStatus
      potentialReadiness
      startDate
      endDate
      attributes
      seceFromSap
      dgsValue
      lotiValue
      campaignOption
      metier
      disciplineCategory
      discipline
      revisionCode
      masterNotification
      functionalLocation
      functionalLocationDescription
      activityType
      shiftType
      sitePlatform
      jobLeader
      jobEstimatedCost
      maximumPob
      fromSap
      siteVisitOnly
      generalStatusList
      activityId
      projectId
      projectWbsGroupCode
      creationDate
      wbsElement
      wbsDescription
      lastUpdateReadiness
      lastSapSyncDate
      flocPlatform
      offshoreFocalPoint
      jobCardMasterTitle
      jobCardMasterId
      twoWeeksFrozenValidated
      isLeader
      parentId
      parentTitle
	  activationPending
    }
  }
`;

export { GET_GENERAL_DETAILS_QUERY };

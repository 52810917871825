<form *ngIf="formGroup" [formGroup]="formGroup" #formRef="ngForm" (ngSubmit)="onSubmit()"
      [ngClass]="{ 'needs-validation': formRef.submitted }">
  <div class="modal-header">
    <div class="d-flex flex-row modal-title">
      <span class="material-icons-outlined text-primary" aria-hidden="true">create</span>
      <h5 class="modal-title">Link JobCards to JobCardMaster</h5>
    </div>
    <button type="button" class="btn btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="alert  alert-message-info d-flex">
        <span class="material-icons">info</span>
        <div>
          Choose the Job Card Master you want to link to the <b>{{linkContext?.jobCardIdsToLinkList?.length || 0}}</b> Job Cards you selected
        </div>
      </div>
      <label class="form-label">Select Job Card Master </label>
      <select id="jobCardMaster" class="form-select" formControlName="jobCardMaster">
        <option *ngFor="let jcm of jobCardMasters" [value]="jcm.id">{{jcm.title}}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer" style="gap: 0">
    <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">
      <span class="material-icons" aria-hidden="true">done</span>
      Validate
    </button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">
      <span class="material-icons" aria-hidden="true">close</span>
      Cancel
    </button>
  </div>
</form>

import gql from "graphql-tag";

const JOB_CARD_DETAILS_UPDATE_MUTATION = gql`
  mutation saveJobCardDetails($affiliate: String, $input: JobCardTracking!) {
    saveJobCardDetails(affiliate: $affiliate, input: $input) {
      prerequisites {
        jobId
        jobScopeDTO {
          id
          methodStatement
          methodStatementStatus
          komJra
          aroRloRequired
          rfmNumber
          rfmNumberValue
          rfmTitle
          rfmClass
          rfmEngineeringStatus
          wellIntervention
          wellInterventionStatus
          costBudget
          yearlyBudgetRequired
          describeActions
        }
        impactDTO {
          id
          impactSimops
          impactSimopsStatus
          impactShortfall
          impactShortfallProduction
          impactShortfallInjection
          impactShortfallFlaring
          impactPermitToWork
          impactPermitsStatus
          impactShutdown
          impactFlaring
          impactHotWork
          impactConfinedSpace
          impactConfinedSpaceProtocolStatus
          impactConfinedSpaceProcedureStatus
          impactRadiography
          impactRadiographyStatus
          impactDivingRov
          impactDivingRovStatus
          impactHydroPressure
          impactHydroPressureStatus
          impactHazardous
          impactHazardousClassifiedArea
          impactHazardousProcedureStatus
          regulatorApproval
          regulatorApprovalDesc
          regulatorApprovalStatus
          greenHouseGas
          ghgYearlyBudgetRequired
          describeActions
        }
        materialListDTO {
          materialStatus
          materials {
            id
            materialDescription
            unloadingPoint
            materialQuantityRequested
            materialQuantityStock
            materialQuantitySite
            sapOrigin
            materialNumber
            workOrderNumber
            jobCardId
            procurementStatus
            eta
            qaQcCertificate
            affiliate
            itemNumber
          }
          fatDTO{
            id
            displayed
            description
            plannedDate
            status
            jobCardId
            readiness
          }
        }
        documentDTOList {
          id
          type
          link
          name
          section
          sectionIndex
          jobCardId
        }
        resourceDTO {
          resourceScaffoldingListDTO {
            id
            displayed
            jobCardId
            description
            status
            jobId
            designSpecificity
            calculationNoteStatus
            schematicStatus
            locationStatus
            dimensionLength
            dimensionWidth
            dimensionHeight
            volume
          }
          resourceLiftingListDTO {
            id
            displayed
            jobCardId
            criticalLifting
            liftingPlanStatus
            liftingDescription
            slsRequired
            craneRequired
            craneDescription
            liftingProcedureRequired
          }
          resourceTemporaryEquipmentListDTO {
            id
            description
            status
            displayed
            jobCardId
            documentId
          }
          resourceIsolationListDTO {
            id
            description
            status
            isolationType
            displayed
            jobCardId
          }
          resourceInhibitionListDTO {
            id
            description
            status
            equipmentTag
            displayed
            jobCardId
          }
          resourceCoreCrewAssistanceListDTO {
            id
            description
            status
            discipline
            displayed
            jobCardId
          }
          resourceContractorListDTO {
            id
            displayed
            contractorActivityVendorJustification
            contractorActivityOrder
            contractorActivityAmount
            contractorActivityServiceOrder
            contractorActivityName
            contractorActivityNumber
            jobCardId
          }
          resourceLogisticListDTO {
            id
            description
            status
            displayed
            jobCardId
          }
          resourceInvitationDTO {
            id
            description
            status
            displayed
            internationalFlights
            invitationVisaStatus
            jobCardId
          }
          resourceCustomPrerequisiteListDTO {
            id
            title
            description
            status
            displayed
            jobCardId
          }
          resourceOnshoreWorksDTO {
            id
            displayed
            jobCardId
            excavationRequired
            pipesAndCableIdentificationRequired
            grassCuttingRequired
            securityBridgingDocumentRequired
            communityEngagementThroughCAPDRequired
          }
          resourceCalorifugeRemovalListDTO {
          id
          displayed
          jobCardId
          calorifugeRemovalNeeded
          equipmentTagAndDescription
          }
        }
      }

      generalDetails {
        id
        jobTitle
        workOrderNumber
        priority
        description
        generalStatus
        actualStatus
        startDate
        endDate
        attributes
        seceFromSap
        discipline
        revisionCode
        masterNotification
        functionalLocation
        functionalLocationDescription
        activityType
        shiftType
        sitePlatform
        jobLeader
        jobEstimatedCost
        maximumPob
        fromSap
        siteVisitOnly
        potentialReadiness
        activityId
        projectId
        dgsValue
        lotiValue
        campaignOption
        lastUpdateReadiness
        wbsElement
        wbsDescription
        creationDate
        lastSapSyncDate
        flocPlatform
        offshoreFocalPoint
        twoWeeksFrozenValidated
        activationPending
        parentId
        parentTitle
        jobCardMasterTitle
        jobCardMasterId
      }
    }
  }
`;

export {JOB_CARD_DETAILS_UPDATE_MUTATION};
